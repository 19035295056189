import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Card, ListGroupItem, ListGroup, Modal, Button, Col, Row, Spinner, Container } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import LoadingScreen from 'react-loading-screen';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';
import "moment/min/locales";
import InfiniteCarousel from 'react-leaf-carousel';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import Select from 'react-select';


import moment from 'moment'
import { FaLocationArrow, FaCalendarDay, } from 'react-icons/fa';
import { IconContext } from "react-icons";
// import "/node_modules/react-image-slider/lib/image-slider.css"

//Image and Logo
import notFound from '../img/NotFound.png';
import calender from '../img/calender.png';
import address from '../img/address.png';
import iconBC from '../img/BCLogo.png';
import openStreetMap from '../img/OpenStreetMap.png';
import HumannitarainMap from '../img/HumanitarainMap.png';
import darkMap from '../img/darkMap.jpg';
import wikimedia from '../img/WikimediaMap.png';
import sattlelite from "../img/sattelite.jpeg";
import HikeBikeMap from "../img/HikeBikeMap.png";
import whiteMap from "../img/whiteMap.png";
import bell from '../img/bell.png';
import map from '../img/map.png';
import fiord from '../img/fiord.jpg';
import grayMap from '../img/grayMap.png';
import modern from '../img/modern.png';
import cancel from '../img/cancel.png';

import KM from 'moment/locale/km';

const sever = "http://51.79.251.248:7005";
// const sever = "http://192.168.1.168:7005";

var getToken = localStorage.getItem('token');
const axios = require('axios').default;

const attributionDefault = '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>';
const urlDefault = 'https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A';
class MapArea extends React.Component {
    intervalID;
    constructor(props) {
        super(props);
        this.markerRefs = []
    }
    state = {
        lat: 11.562108,
        lng: 104.888535,
        zoom: 15,
        isMapInit: false,
        dataProvice: [],
        isloading: true,
        openData: false,
        listCustomerMarket: false,
        display_name: "",
        markers: [],
        selectOptionsProvince: [],
        selectOptionsDistrict: [],
        selectOptionsCustomer: [],
        name: "",
        isOpen: false,
        openPopup: false,
        key: 0,
        image: "",
        customerbyID: [],
        districtData: [],
        images: [],
        customerMarket: [],
        id: "",
        profileOpen: false,
        noDataAlert: false,
        profile: '',
        getName: "",
        disableCustomer: true,
        mapLoading: false,
        clearselectedDistrict: null,
        clearselectCustomers: null,
        openPanelRightMap: false,
        attribution: attributionDefault,
        urlMap: urlDefault,
        colorMarker: "#1aade6d9",
        marginCard: '10px 50px 0px 0px',
        opacity: '0.9',
        colorsByMap: "#242424",
        borderColorByMap: "#1aade6d9",
        textColorByMap: 'white',
        lineColorByMap: '#1aade6d9',
    };

    componentDidMount() {
        // this._getAPISaler();
        this._getDataAllCustomer();
        // const getName = localStorage.getItem('name');
        document.title = `Customers Area`;
        // this.setState({ getName, isOpen: false, profileOpen: false })
    }

    _getDataAllCustomer() {
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        let url1 = `${sever}/api/v1/customer/nodetail`;
        let url2 = `${sever}/api/v1/province`;
        let one = axios.get(url1, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        });
        let two = axios.get(url2, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        })
        axios.all([one, two]).then(axios.spread((...responses) => {
            console.log("CountCustomer", responses[0].data['CustomerData'].length)
            const datamap = responses[0].data['CustomerData'];
            const dataProvice = responses[1].data['provinces'];
            const isloading = false;
            const disableCustomer = false;
            this.setState({ datamap, isloading, dataProvice, disableCustomer });
            this._getOptionCustomers();
            // use/access the results 
        })).catch(errors => {
            // react on errors.
        });
        // axios.get(url, {
        //     headers: {
        //         'Authorization': `Bearer ${getToken}`,
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //     }
        // }).then((res) => {
        //     const datamap = res.data['CustomerData'];
        //     const isloading = false;
        //     const disableCustomer = false;
        //     this.setState({ datamap, isloading, disableCustomer });
        //     this._getOptionCustomers()
        // }).catch((err) => {
        //     // console.log("AXIOS ERROR: ", err);
        // });
    }

    _getOptionCustomers() {
        const optionsCustomer = this.state.datamap.map(d => {
            var latlong = d.GOOGLE_MAP.split(",");
            var latitude = parseFloat(latlong[0]);
            var longitude = parseFloat(latlong[1]);
            var data = ({
                "value": d.ADD_CODE,
                "label": d.ADD_LINE_1,
                "PICTURE": d.PICTURE,
                "lat": latitude,
                "lng": longitude
            })
            return data;
        }
        );

        const optionProvince = this.state.dataProvice.map(d => {
            var data = ({
                "value": d.PROID,
                "label": d.PRO_NAME,
            })
            return data;
        }
        );

        const optionDestrick = this.state.districtData.map(d => {
            var data = ({
                "value": d.D_ID,
                "label": d.D_NAME,
            })
            return data;
        }
        );

        this.setState({ selectOptionsCustomer: optionsCustomer, selectOptionsProvince: optionProvince, selectOptionsDistrict: optionDestrick, listCustomerMarket: false });
    }

    _getDataCustomer(e) {
        this.setState({ mapLoading: true, id: e.value, name: e.label, selectOptionsDistrict: [] });
        console.log(e)
        let url1 = `${sever}/api/v1/customer/getcustomerbyprovince?provinceid=` + e.value;
        let url2 = `${sever}/api/v1/district/getbyprovince?pid=` + e.value;

        let one = axios.get(url1, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        });

        let two = axios.get(url2, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        });

        axios.all([one, two]).then(axios.spread((...responses) => {
            const datamap = responses[0].data['CustomerData'];
            const districtData = responses[1].data['districts'];
            const clearselectedDistrict = null;
            const clearselectCustomers = null;
            if (datamap.length == 0) {
                const noDataAlert = true;
                const disableCustomer = true;
                const mapLoading = false;
                const datamap = [];
                const selectOptionsCustomer = [];
                this.setState({ noDataAlert, datamap, clearselectCustomers, clearselectedDistrict, mapLoading, disableCustomer, selectOptionsCustomer, districtData });
            } else {
                const disableCustomer = false;
                const mapLoading = false;
                this.setState({ datamap, clearselectCustomers, clearselectedDistrict, districtData, mapLoading, disableCustomer });
            }
            this._getOptionCustomers();
        }))

        // axios.get(url, {
        //     headers: {
        //         'Authorization': `Bearer ${getToken}`,
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //     }
        // }).then((res) => {
        //     const datamap = res.data['CustomerData'];
        //     if (datamap.length == 0) {
        //         const noDataAlert = true;
        //         const disableCustomer = true;
        //         const mapLoading = false;
        //         const datamap = [];
        //         const selectOptionsCustomer = [];
        //         this.setState({ noDataAlert, datamap, mapLoading, disableCustomer, selectOptionsCustomer });
        //     } else {
        //         const disableCustomer = false;
        //         const mapLoading = false;
        //         this.setState({ datamap, mapLoading, disableCustomer });
        //         this._getOptionCustomers();
        //     }
        // }).catch((err) => {
        //     // console.log("AXIOS ERROR: ", err);
        // });
    }

    _getDataCustomersByCommu(e) {
        this.setState({ selectOptionsCustomer: [], mapLoading: true, clearselectedDistrict: e });
        let url = `${sever}/api/v1/customer/getcustomerbydistrict?districtid=${e.value}`;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            const datamap = res.data['CustomerData'];
            const clearselectCustomers = null;
            if (datamap.length == 0) {
                const noDataAlert = true;
                const disableCustomer = true;
                const mapLoading = false;
                const datamap = [];
                const selectOptionsCustomer = [];
                this.setState({ noDataAlert, clearselectCustomers, datamap, mapLoading, disableCustomer, selectOptionsCustomer });
            } else {
                const disableCustomer = false;
                const mapLoading = false;
                this.setState({ datamap, clearselectCustomers, mapLoading, disableCustomer });
            }
            this._getOptionCustomers()
        }).catch((err) => {
            // console.log("AXIOS ERROR: ", err);
        });
    }

    handleChange(e) {
        this.markerRefs[e.value].leafletElement.openPopup()
        this.setState({ id: e.value, name: e.label, clearselectCustomers: e });
        console.log("HereLatlng", [e.lat, e.lng])
        this.mapEl.leafletElement.flyTo([e.lat, e.lng], 15);
        console.log(e.value)
        this._getImages(e)
    }

    handleDetailCustomer(e) {
        console.log("DetailCustomer", e)
        this.markerRefs[e.ADD_CODE].leafletElement.openPopup()
        this.setState({ id: e.ADD_CODE, name: e.ADD_LINE_1 });
        var latlong = e.GOOGLE_MAP.split(",");
        var latitude = parseFloat(latlong[0]);
        var longitude = parseFloat(latlong[1]);
        console.log("HereLatlng", [latitude, longitude])
        this.mapEl.leafletElement.flyTo([latitude, longitude], 15);
        this._getImages(e)
    }

    onCloseModal = () => {
        this.setState({ noDataAlert: false });
    };

    _getCustomerMarkets(e) {
        this.setState({ customerbyID: [], images: [] })
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        var codeID = (e.MARKET_ID == null) ? e.value : e.MARKET_ID;
        // let url1 = `${sever}/api/v1/customer/getcustomerbyid?addcode=` + codeID;
        let url = `${sever}/api/v1/customer/getbymarketidnodetail?marketid=` + codeID;
        console.log("DataCustomerbyMarket", e.MARKET_ID);
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            console.log("DataCustomerbyMarket", res.data);
            const customerMarket = res.data['CustomerData'];
            this.setState({ customerMarket, listCustomerMarket: true })
            // use/access the results 
        }).catch((err) => {
            // console.log("AXIOS ERROR: ", err);
        });
    }

    _getImages(e) {
        this.setState({ customerbyID: [], images: [] })
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        var codeID = (e.ADD_CODE == null) ? e.value : e.ADD_CODE;
        let url1 = `${sever}/api/v1/customer/getcustomerbyid?addcode=` + codeID;
        let url2 = `${sever}/api/v1/customer/getimagesbyid?addcode=` + codeID;

        let one = axios.get(url1, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        });
        let two = axios.get(url2, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        })
        axios.all([one, two]).then(axios.spread((...responses) => {
            const customerbyID = responses[0].data['CustomerData'];
            const images = responses[1].data;
            this.setState({ customerbyID, images, openData: true })
            // use/access the results 
        })).catch(errors => {
            // react on errors.
        });
    }

    // showDetail = (e) => {
    //     const axios = require('axios').default;
    //     axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${e.LATITUDE},${e.LONGITUDE}.json?access_token=pk.eyJ1Ijoia2ltbG9uZyIsImEiOiJjazA0eXNoY2UzbGplM2Nxb2hkeDM2MjJwIn0.CEHxdBbcFkTK4d-baKIwEg`
    //     ).then((res) => {
    //         if (res.statusText == "OK") {
    //             console.log(res.data);
    //             const display_name = res.data.display_name;
    //             this.setState({ display_name, isLoading: false });
    //             e.target.openPopup();
    //         } else {
    //             console.log(res.statusText);
    //         }
    //     });
    // }

    convertLatLng = e => {
        var latlong = e.split(",");
        var latitude = parseFloat(latlong[0]);
        var longitude = parseFloat(latlong[1]);
        const markers = [latitude, longitude];
        return (markers);
    }

    render() {
        const { lat, lng, zoom, } = this.state;
        const position = [lat, lng];
        const styles = {
            card: {
                borderRadius: 55,
            },
            cardImage: {
                height: 125,
                width: 125,
                backgroundColor: 'white'
            },
            container: {
                shadowColor: 'rgba(0,0,0, .2)',
                shadowOffset: { height: 0, width: 0 },
                shadowOpacity: 0, //default is 1
                shadowRadius: 0//default is 1
            },
            imageList: {
                height: 50,
                width: 50,
                borderRadius: '50%',
                position: 'relative',
            }
        }
        console.log("DataProvince", this.state.selectOptionsProvince)
        if (!this.state.isloading) {
            return (
                <div>
                    <div class='row' style={{ width: "100%", height: "100vh", marginLeft: 0, overflow: 'hidden' }}>
                        <Col class="map" style={{ padding: 0, margin: 0 }}>
                            {
                                (this.state.mapLoading) ? <LoadingScreen
                                    loading={true}
                                    bgColor='#C0C0C0'
                                    spinnerColor='#9ee5f8'
                                    textColor='#676767'
                                    text='Loading data...'
                                >
                                </LoadingScreen>
                                    :
                                    // onClick={() => this.setState({ listCustomerMarket: false })}
                                    <Map center={position} zoom={zoom} zoomControl={false} ref={(mapEl) => this.mapEl = mapEl} style={{ height: '100vh', position: 'ralative' }}>
                                        <Col>
                                            <Button className='overlayButtonAreaLayout' style={{ backgroundColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", color: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", borderColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0" }}
                                                onClick={() => this.setState({ openPanelRightMap: !this.state.openPanelRightMap, openPanelRightNoti: false, marginCard: (!this.state.openPanelRightMap) ? "10px 20% 0px 0px" : "10px 50px 0px 0px" })}
                                            >
                                                <img src={map} style={{ width: 20, height: 20 }} />
                                            </Button>
                                        </Col>
                                        {this.state.datamap != null && this.state.datamap.map(({ ADD_CODE, PICTURE, GOOGLE_MAP, MARKET_ID }) =>
                                            <Marker key={ADD_CODE} icon={L.divIcon({
                                                className: 'custom-div-icon',
                                                // http://192.168.1.168:7005/${PICTURE}
                                                html: `<div style='background-color:${this.state.colorMarker}; opacity: ${this.state.opacity}' class='marker-pin'></div>` + `<img class='awesome' src='${sever}/api/v1/imageresize?url=${PICTURE}&quality=5' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                // html: "<div style='background-color:#4838cc;' class='marker-pin'></div>" + `<img class='awesome' src='http://51.79.251.248:7005/api/v1/imageresize?url=${PICTURE}' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                iconSize: [30, 42],
                                                iconAnchor: [15, 42]
                                            })}

                                                ref={ref => this.markerRefs[ADD_CODE] = ref}
                                                onClick={() => this._getCustomerMarkets({ MARKET_ID })}
                                                position={this.convertLatLng(GOOGLE_MAP)}>
                                                <Popup classeName="popup-custom" border='none' position={this.state.selectOptions} closeButton={false} ref={this.popup}>
                                                    {
                                                        (this.state.customerbyID.length != 0) ? this.state.customerbyID.map(({ ADD_LINE_1, SEX, TB_BCMARKET, STORE, ADD_TEL, HOUSE_NO, COMMUNE, DISTRICT, PROVINCE, USER_CREA }) =>
                                                            <div style={{ backgroundColor: this.state.colorsByMap, opacity: this.state.opacity, borderColor: this.state.borderColorByMap, borderWidth: 1, borderStyle: 'solid', borderRadius: 25, }}>
                                                                <Row style={{ justifyContent: 'center', }}>
                                                                    <div class="avatar-flipArea" style={{ backgroundColor: 'rgba(240,240,240,0.1)' }}>
                                                                        <img style={styles.cardImage} onClick={() => this.setState({ profileOpen: true, profile: PICTURE })} variant="top" class='cover' onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${sever}/api/v1/imageresize?url=${PICTURE}&quality=20`} />
                                                                    </div>
                                                                </Row>
                                                                <div style={{ marginTop: '70px', padding: '15px 15px 10px 15px' }}>
                                                                    <Card.Title style={{ fontFamily: "Romnea", fontSize: '30px', textAlign: 'center', color: this.state.textColorByMap }}>{ADD_LINE_1}</Card.Title>
                                                                    <Row>
                                                                        <Col md={5} style={{ paddingRight: 0 }}>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>លេខកូដ</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>ភេទ</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>ផ្សារ</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>តូបលេខ</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>លេខទំនាក់ទំនង</Card.Text>
                                                                        </Col>
                                                                        <Col md={1} style={{ paddingRight: 0 }}>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>៖</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>៖</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>៖</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>៖</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>៖</Card.Text>
                                                                        </Col>
                                                                        <Col md={6} style={{ paddingRight: 0 }}>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>{ADD_CODE}</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>{(SEX == "M") ? "ប្រុស" : "ស្រី"}</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>{TB_BCMARKET.MARKET_KHMER_NAME}</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>{STORE}</Card.Text>
                                                                            <Card.Text className='khmer-font-static' style={{ color: this.state.textColorByMap }}>{ADD_TEL}</Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                                <Row className='justify-content-md-center' style={{ margin: 10, }}>
                                                                    <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src={address} style={{ height: 30, width: 30 }} />
                                                                    </Col>
                                                                    <Col style={{ padding: 0, width: 'fit-content', }}><Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                        ផ្ទះលេខ: {(HOUSE_NO == null) ? "" : HOUSE_NO}, សង្កាត់/ឃុំ: {(COMMUNE == null) ? "" : COMMUNE.C_NAME}, ស្រុក/ខណ្ឌ: {(DISTRICT == null) ? "" : DISTRICT.D_NAME}, ខេត្ត/ក្រុង: {(PROVINCE == null) ? "" : PROVINCE.PRO_NAME}
                                                                    </Card.Text>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                                <Row style={{ margin: 10, }}>
                                                                    <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src={calender} style={{ height: 30, width: 30 }} />
                                                                    </Col>
                                                                    <Col style={{ padding: 0, width: 'fit-content' }}>
                                                                        <Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                            {moment.parseZone(USER_CREA).locale('km').format(`ថ្ងៃ dddd, Do ខែ MMMM ឆ្នាំ YYYY, hh:mm នាទី a`)}
                                                                        </Card.Text>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                                <div className="caurosel-div" style={{ padding: 5 }}>
                                                                    {
                                                                        (this.state.openData !== false) ?
                                                                            <InfiniteCarousel
                                                                                dots={false}
                                                                                showSides={true}
                                                                                sideSize={0}
                                                                                slidesToScroll={2}
                                                                                slidesToShow={2}
                                                                                scrollOnDevice={true}
                                                                                incrementalSides={true}
                                                                                cycleInterval={true}
                                                                                breakpoints={[
                                                                                    {
                                                                                        breakpoint: 500,
                                                                                        settings: {
                                                                                            slidesToShow: 2,
                                                                                            slidesToScroll: 2,
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        breakpoint: 768,
                                                                                        settings: {
                                                                                            slidesToShow: 3,
                                                                                            slidesToScroll: 3,
                                                                                        },
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                {
                                                                                    this.state.images.map((imageList, index) => <div>
                                                                                        <img
                                                                                            style={{ borderRadius: 25, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.borderColorByMap }}
                                                                                            class="cover narrow"
                                                                                            onError={(e) => { e.target.onerror = null; e.target.src = notFound }}
                                                                                            // alt={console.log(`http://51.79.251.248:7005/${imageList.IMAGE}`)}
                                                                                            // alt={console.log(notFound)}
                                                                                            // src={(imageList == null) ? notFound:`http://192.168.1.168:7005/${imageList.IMAGE}`}
                                                                                            src={`${sever}/api/v1/imageresize?url=${imageList.IMAGE}&quality=10`}
                                                                                            onClick={() => this.setState({ isOpen: true, key: index })}
                                                                                        />
                                                                                    </div>
                                                                                    )
                                                                                }

                                                                            </InfiniteCarousel>
                                                                            : <Col style={{ justifyContent: 'center', justifyItems: 'center', height: 20 }}><Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                role="status"
                                                                            /></Col>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : (this.state.customerMarket.length == 0) ? <Col style={{ width: 'fit-content', height: 30, display: 'flex', borderRadius: 100, justifyContent: 'center', alignItems: 'center' }}><Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        /></Col> : null
                                                    }
                                                </Popup>
                                            </Marker>)
                                        }
                                        <TileLayer
                                            attribution={this.state.attribution}
                                            url={this.state.urlMap}
                                            minZoom={1}
                                            maxZoom={19}
                                        />
                                    </Map>
                            }
                        </Col>
                        {
                            this.state.openPanelRightMap && <Col md={2} style={{ backgroundColor: "#F0F0F0", width: 'fit-content', height: "100%", padding: 10 }}>
                                <Row style={{ margin: 10, justifyContent: 'start' }}>
                                    <Card.Title style={{ fontFamily: 'Romnea', fontSize: 18, padding: 10 }}>
                                        ជ្រើសរើសផែនទី
                            </Card.Title>
                                </Row>
                                <div onClick={() =>
                                    this.setState({ attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទីដើម</Row>
                                        <Row>
                                            <img src={openStreetMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() =>
                                    this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '`&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`', urlMap: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Humannitarain</Row>
                                        <Row>
                                            <img src={HumannitarainMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ colorMarker: '#cefcb8', attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community', urlMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី Sattelite</Row>
                                        <Row>
                                            <img src={sattlelite} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ colorMarker: '#289812', attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី HikeBikeMap</Row>
                                        <Row>
                                            <img src={HikeBikeMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទីខ្មៅ</Row>
                                        <Row>
                                            <img src={darkMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13,
                                        }}>ផែនទី Modern</Row>
                                        <Row>
                                            <img src={modern} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://maps.targomo.com/styles/fiord-color-gl-style/rendered/{z}/{x}/{y}.png" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13,
                                        }}>ផែនទី FiordColor</Row>
                                        <Row>
                                            <img src={fiord} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: 'https://maps.targomo.com/styles/gray-gl-style/rendered/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី Grey</Row>
                                        <Row>
                                            <img src={grayMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី ស</Row>
                                        <Row>
                                            <img src={whiteMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                            </Col>
                        }
                    </div>
                    <div class="overlay">
                        <Modal className="modal-container"
                            show={this.state.noDataAlert}
                            onHide={this.onCloseModal}
                            animation={true}
                            size="sm"
                            centered
                            bsSize="small">
                            <Modal.Header>
                                <Modal.Title className="fix-style">មិនមានអតិថជន...</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button onClick={this.onCloseModal} className="khmer-font">បិទ</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div class="overlay" style={{ borderColor: this.state.borderColorByMap, backgroundColor: this.state.colorsByMap, opacity: this.state.opacity, borderStyle: 'solid', borderWidth: 1,width: "fit-content", borderRadius: 15 }}>
                        <div style={{ padding: 5 }}>
                            <Select className='khmer-font-select' menuPlacement="auto"
                                menuPosition="fixed" placeholder={"តាមខេត្ត/ក្រុង"} options={this.state.selectOptionsProvince} onChange={this._getDataCustomer.bind(this)} />
                        </div>
                        <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                        <div style={{ padding: 5 }}>
                            <Select className='khmer-font-select' menuPlacement="auto" value={this.state.clearselectedDistrict}
                                menuPosition="fixed" placeholder={"តាមស្រុក/ខណ្ឌ"} options={this.state.selectOptionsDistrict} onChange={this._getDataCustomersByCommu.bind(this)} /></div>
                        <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                        <div style={{ padding: 5, backgroundColor: this.state.colorsByMap }}>
                            <Select className='khmer-font-select' menuPlacement="auto" value={this.state.clearselectCustomers} isDisabled={this.state.disableCustomer}
                                menuPosition="fixed" placeholder={"ស្វែងរកអតិថជន"} options={this.state.selectOptionsCustomer} onChange={this.handleChange.bind(this)} /></div>
                        <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                        <Row className='justify-content-md-center' style={{ margin: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className='khmer-font' style={{ textAlign: "center", backgroundColor: this.state.colorsByMap, color: this.state.textColorByMap, paddingRight: 10 }}>ចំនួនអតិថជន ៖​​ </div>
                                <div style={{ textAlign: "center", backgroundColor: this.state.colorsByMap, color: this.state.textColorByMap, fontFamily: 'Romnea', fontSize: 16 }}>{this.state.selectOptionsCustomer.length} នាក់</div>
                            </div>
                        </Row>
                    </div>

                    {
                        this.state.listCustomerMarket && <div class="overlayMarket" style={{ opacity: this.state.opacity, backgroundColor: this.state.colorsByMap, border: '1px solid', borderColor: this.state.borderColorByMap, }}>
                            <Col style={{ backgroundColor: this.state.colorsByMap, borderRadius: 25, justifyContent: 'center', padding: 10 }} class="align-content-md-start">
                                <Col className='align-items-end' style={{ position: 'overlay' }}>
                                    <Row className='justify-content-md-end' style={{ padding: '5px 0px 0px 0px' }}>
                                        <div onClick={() => this.setState({ listCustomerMarket: !this.state.listCustomerMarket })}>
                                            <img src={cancel} style={{ width: 25, height: 25 }} />
                                        </div>
                                    </Row>
                                </Col>
                                <Row style={{ margin: '0px 0px 0px 5px', justifyContent: 'center' }}>
                                    <Col className='justify-content-md-center'>
                                        <Card.Title style={{ fontFamily: 'Romnea', fontSize: 25, color: this.state.textColorByMap, opacity: '100%', wordBreak: 'break-all' }}>
                                            <p> ផ្សារ ៖ ​​{this.state.customerMarket[0]['TB_BCMARKET']['MARKET_KHMER_NAME']}</p>
                                        </Card.Title>
                                    </Col>
                                </Row>
                                <Row style={{ margin: '0px 0px 0px 5px', justifyContent: 'center' }}>
                                    <Col className='justify-content-md-center'>
                                        <Card.Title style={{ fontFamily: 'Romnea', fontSize: 25, color: this.state.textColorByMap, opacity: '100%', wordBreak: 'break-all' }}>
                                            អតិថជន ({this.state.customerMarket.length} នាក់)
                                            </Card.Title>
                                    </Col>
                                </Row>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                <Row>
                                    <ul className="list-group" style={{ backgroundColor: this.state.colorsByMap, height: '300px', width: '290px', margin: '0px 15px 15px 15px', maxHeight: 'fit-content', overflowX: 'hidden', overflowY: 'scroll' }}>
                                        {this.state.customerMarket.map((listitem, index) => (
                                            <li onClick={() => this.handleDetailCustomer(listitem)} className="list-group-item" style={{ backgroundColor: this.state.colorsByMap, border: 1 }}>
                                                <Row className='item-sidebar' style={{ marginRight: 0 }}>
                                                    <Col xs={1} sm={1} md={1} lg={1} style={{ display: 'flex', alignItems: 'center' , padding: 0}}>
                                                        <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: this.state.textColorByMap, opacity: '100%' }}>{`${index + 1}`}</div>
                                                    </Col>
                                                    <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                                        <div style={{
                                                            border: '1px solid',
                                                            borderColor: this.state.borderColorByMap,
                                                            borderWidth: 1,
                                                            borderRadius: '50%',
                                                        }}>
                                                            <img onError={(e) => { e.target.onerror = null; e.target.src = notFound }} style={styles.imageList} src={`${sever}/api/v1/imageresize?url=${listitem.PICTURE}&quality=50`} />
                                                        </div>
                                                    </Col>
                                                    <Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                                        <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: this.state.textColorByMap, opacity: '100%' }}>{`${listitem.ADD_LINE_1}`}</div>
                                                    </Col>
                                                    <Col style={{ display: 'flex', alignItems: 'center', padding: 0, justifyContent: 'center' }}>
                                                        <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: this.state.textColorByMap, opacity: '100%' }}>{`${listitem.TOTAL} $`}</div>
                                                    </Col>
                                                </Row>
                                            </li>
                                        ))}
                                    </ul>
                                </Row>
                            </Col>
                        </div>
                    }

                    {
                        this.state.profileOpen &&
                        <div class="overlay">
                            <Lightbox
                                mainSrc={`${sever}/${this.state.profile}`}
                                onError={(e) => { e.target.onerror = null; e.target.src = notFound }}
                                onCloseRequest={() => this.setState({ profileOpen: false })}
                            />
                        </div>
                    }
                    {
                        this.state.isOpen &&
                        <div class="overlay">
                            <Lightbox
                                mainSrc={`${sever}/${this.state.images[this.state.key].IMAGE}`}
                                nextSrc={`${sever}/${this.state.images[(this.state.key + 1) % this.state.images.length].IMAGE}`}
                                prevSrc={`${sever}/${this.state.images[(this.state.key + this.state.images.length - 1) % this.state.images.length].IMAGE}`}
                                // mainSrc={(this.state.images[this.state.key].IMAGE.length == 0) ? notFound : `http://192.168.1.168:7000/${this.state.images[this.state.key].IMAGE}`}
                                // nextSrc={`http://192.168.1.168:7000/${this.state.images[(this.state.key + 1) % this.state.images.length].IMAGE}`}
                                // prevSrc={`http://192.168.1.168:7000/${this.state.images[(this.state.key + this.state.images.length - 1) % this.state.images.length].IMAGE}`}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        key: (this.state.key + this.state.images.length - 1) % this.state.images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        key: (this.state.key + 1) % this.state.images.length
                                    })
                                }
                            />
                        </div>
                    }

                </div >
            )
        } else {
            return <LoadingScreen
                loading={true}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767'
                text='Loading data...'
            >
            </LoadingScreen>
        }
    }
}

export default withRouter(MapArea);
