import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Map from "../home/mapHome"
import MapComponent from "../detail/map";
import MapCustomer from "../customers/map";
import MapArea from "../area/map";
import login from "../auth/login"
import openSocket from "socket.io-client";

import 'bootstrap/dist/css/bootstrap.min.css';
const sever = "http://51.79.251.248:7005";
const axios = require('axios').default;
const socket = openSocket("http://51.79.251.248:7007", { reconnect: true, forceNew: true, upgrade: false, transports: ['websocket'], autoConnect: true, reconnectionDelayMax: 2000 });
let idUser = window.localStorage.getItem('idUser');
let token = window.localStorage.getItem('token');
export default function Routes() {
  // window.localStorage.clear()
  async function checkAuth() {
    console.info('Check auth')
    //request axios
    let url = `${sever}/api/v1/userlocation?lastdate=true`;
    axios.get(url, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    }).then((res) => {
      if (res.status == 200) {
        console.info('status 200')
        return true;
      } else {
        window.localStorage.clear();
        console.info('error status')
        // window.localStorage.clear();
        // window.location.href('/login')
        return false;
      }
    }).catch((err) => {
      window.localStorage.clear();
      window.location.reload();
      return false
    });
  }
  if (window.localStorage.getItem('token') && checkAuth()) {

    socket.emit('global_notification', [idUser]);

    return <Switch>
      <Route path="/" exact component={Map} />
      <Route path="/detail/:id" exact component={MapComponent} />
      <Route path="/customer/:id" exact component={MapCustomer} />
      <Route path="/area/" exact component={MapArea} />
      {/* <Route
          path="/detail/:id"
          render={({ match }) => <MapComponent match={match} />}
        /> */}
      {/* <Route component={Map}/> */}
    </Switch>
  } else {
    console.info('ot hery')
    return <Route path="*" exact component={login} />
  }

}