import React from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Card, ListGroupItem, Spinner, Col, Row, Modal, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import LoadingScreen from 'react-loading-screen';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';
import "moment/min/locales";
import InfiniteCarousel from 'react-leaf-carousel';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import Select from 'react-select';
import moment from 'moment'
import { FaLocationArrow, FaCalendarDay, } from 'react-icons/fa';
import { IconContext } from "react-icons"
// import "/node_modules/react-image-slider/lib/image-slider.css"

// Image import

import calender from '../img/calender.png';
import address from '../img/address.png';
import notFound from '../img/NotFound.png';
import iconBC from '../img/BCLogo.png'


import KM from 'moment/locale/km';

const sever = "http://51.79.251.248:7005";
// const sever = "http://192.168.1.168:7005";

class MapCustome extends React.Component {
    intervalID;
    constructor(props) {
        super(props);
        this.markerRefs = []
    }
    state = {
        lat: 11.562108,
        lng: 104.888535,
        zoom: 15,
        isMapInit: false,
        datamap: [],
        isloading: true,
        openData: false,
        display_name: "",
        markers: [],
        selectOptions: [],
        selectOption: true,
        noDataAlert: false,
        name: "",
        isOpen: false,
        openPopup: false,
        key: 0,
        image: "",
        images: [],
        id: "",
        profileOpen: false,
        profile: '',
        getName: "",
    };

    componentDidMount() {
        this._getAPISaler();
        const getName = localStorage.getItem('name');
        document.title = `${getName} Customers`;
        this.setState({ getName, isOpen: false, profileOpen: false })
    }

    getOptions() {
        const options = this.state.datamap.map(d => {
            var latlong = d.GOOGLE_MAP.split(",");
            var latitude = parseFloat(latlong[0]);
            var longitude = parseFloat(latlong[1]);
            var data = ({
                "value": d.ADD_CODE,
                "label": d.ADD_LINE_1,
                "PICTURE": d.PICTURE,
                "lat": latitude,
                "lng": longitude
            })
            return data;
        }
        )
        if (options.length != 0) {
            this.setState({ selectOptions: options, selectOption: false })
        } else {
            this.setState({ noDataAlert: true })
        }
    }

    openPopUp(marker, id) {
        if (marker && marker.leafletElement) {
            marker.leafletElement.openPopup(id);
        }
    }

    handleChange(e) {
        this.markerRefs[e.value].leafletElement.openPopup()
        this.setState({ id: e.value, name: e.label });
        this.mapEl.leafletElement.flyTo([e.lat, e.lng], 15);
        this._getImages(e)
    }

    _getAPISaler() {
        const { id } = this.props.match.params;
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        let url = `${sever}/api/v1/customer/getbyuserid?typeid=` + id;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            console.log(res.data['CustomerData']);
            const datamap = res.data['CustomerData'];
            const isloading = false;
            this.setState({ datamap, isloading, profileOpen: false, isOpen: false });
            this.getOptions();
        }).catch((err) => {
            // console.log("AXIOS ERROR: ", err);
        });
    }

    _getImages(e) {
        this.setState({ images: [], openData: false });
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        var codeID = (e.ADD_CODE == null) ? e.value : e.ADD_CODE;
        // let url = `http://192.168.1.168:7005/api/v1/customer/getimagesbyid?addcode=` + codeID;
        let url = `${sever}/api/v1/customer/getimagesbyid?addcode=` + codeID;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            this.setState({ images: res.data, openData: true });
        }).catch((err) => {
            // console.log("AXIOS ERROR: ", err);
        });
    }

    onCloseModal = () => {
        this.setState({ noDataAlert: false });
    };

    polylineLineMaker() {
        const geojsonDataByLines = this.state.datamap;

        const testMe = geojsonDataByLines.map((cord) => {
            return [cord.LATITUDE, cord.LONGITUDE];
        });
        return testMe;
    }

    // showDetail = (e) => {
    //     const axios = require('axios').default;
    //     axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${e.LATITUDE},${e.LONGITUDE}.json?access_token=pk.eyJ1Ijoia2ltbG9uZyIsImEiOiJjazA0eXNoY2UzbGplM2Nxb2hkeDM2MjJwIn0.CEHxdBbcFkTK4d-baKIwEg`
    //     ).then((res) => {
    //         if (res.statusText == "OK") {
    //             console.log(res.data);
    //             const display_name = res.data.display_name;
    //             this.setState({ display_name, isLoading: false });
    //             e.target.openPopup();
    //         } else {
    //             console.log(res.statusText);
    //         }
    //     });
    // }

    convertLatLng = e => {
        var latlong = e.split(",");
        var latitude = parseFloat(latlong[0]);
        var longitude = parseFloat(latlong[1]);
        const markers = [latitude, longitude];
        return (markers);
    }

    render() {
        const { lat, lng, zoom, } = this.state;
        const position = [lat, lng];
        const styles = {
            card: {
                borderRadius: 55,
            },
            cardImage: {
                height: 125,
                width: 125,
                position: 'cover',
                backgroundColor: 'white'
            },
            container: {
                shadowColor: 'rgba(0,0,0, .2)',
                shadowOffset: { height: 0, width: 0 },
                shadowOpacity: 0, //default is 1
                shadowRadius: 0//default is 1
            }
        }
        if (!this.state.isloading) {
            return (
                <div>
                    <div class="map">
                        <Map center={position} zoom={zoom} zoomControl={false} ref={(mapEl) => this.mapEl = mapEl} style={{ height: '100vh', position: 'ralative' }}>
                            {this.state.datamap.map(({ ID, GOOGLE_MAP, ADD_LINE_1, ADD_TEL, STORE, TB_BCMARKET, USER_CREA, SEX, PICTURE, COMMUNE, DISTRICT, HOUSE_NO, PROVINCE, ADD_CODE }) =>
                                <Marker key={ID} icon={L.divIcon({
                                    className: 'custom-div-icon',
                                    // html: "<div style='background-color:#4838cc;' class='marker-pin'></div>" + `<img class='awesome' src='http://192.168.1.168:6000/${PICTURE}' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                    html: "<div style='background-color:#4838cc;' class='marker-pin'></div>" + `<img class='awesome' src='${sever}/api/v1/imageresize?url=${PICTURE}&quality=5' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                    iconSize: [30, 42],
                                    iconAnchor: [15, 42]
                                })}
                                    // ref={console.log(PICTURE.length)}
                                    ref={ref => this.markerRefs[ADD_CODE] = ref}
                                    onClick={() => this._getImages({ ADD_CODE, PICTURE })}
                                    position={this.convertLatLng(GOOGLE_MAP)}>
                                    <Popup classeName="popup-custom" border='none' position={this.state.selectOptions} closeButton={false} ref={this.popup}>
                                        <div style={{ backgroundColor: 'rgba(240,240,240,0.1)' }}>
                                            <Row style={{ justifyContent: 'center', }}>
                                                <div class="avatar-flip">
                                                    <img style={styles.cardImage} onClick={() => this.setState({ profileOpen: true, profile: PICTURE })} variant="top" class='cover' onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${sever}/api/v1/imageresize?url=${PICTURE}&quality=20`} />
                                                    {/* <Card.Img variant="top" class='cover' src={(PICTURE.length == 0) ? notFound : `http://192.168.1.168:7000/${PICTURE}`} style={styles.cardImage} /> */}
                                                </div>
                                            </Row>
                                            <div style={{ marginTop: '70px', padding: '15px 15px 10px 15px' }}>
                                                <Card.Title style={{ fontFamily: "Romanea", fontSize: '30px', textAlign: 'center' }}>{ADD_LINE_1}</Card.Title>
                                                <Row>
                                                    {/* <Card.Text className='khmer-font'>លេខកូដ ៖ {ADD_CODE}
</Card.Text>
<Card.Text className='khmer-font'>
ភេទ ៖ {(SEX == "M") ? "ប្រុស" : "ស្រី"}
</Card.Text>
<Card.Text className='khmer-font'>ផ្សារ ៖ {TB_BCMARKET.MARKET_KHMER_NAME}
</Card.Text>
<Card.Text className='khmer-font'>តូបលេខ ៖ {STORE}
</Card.Text>
<Card.Text className='khmer-font'>
លេខទំនាក់ទំនង ៖ {ADD_TEL}
</Card.Text>
<Card.Text className='khmer-font'>
{moment.parseZone(USER_CREA).locale('km').format(`ថ្ងៃ dddd, Do ខែ MMMM ឆ្នាំ YYYY,     ម៉ោង hh:mm នាទី a`)}
</Card.Text> */}
                                                    <Col md={5} style={{ paddingRight: 0 }}>
                                                        <Card.Text className='khmer-font-static'>លេខកូដ
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>
                                                            ភេទ
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>ផ្សារ
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>តូបលេខ
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>
                                                            លេខទំនាក់ទំនង
        </Card.Text>
                                                    </Col>
                                                    <Col md={1} style={{ paddingRight: 0 }}>
                                                        <Card.Text className='khmer-font-static'>៖
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>៖
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>៖
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>៖
        </Card.Text>
                                                        <Card.Text className='khmer-font-static'>៖
        </Card.Text>
                                                    </Col>
                                                    <Col md={6} style={{ paddingRight: 0 }}>
                                                        <Card.Text className='khmer-font-dynamic'>{ADD_CODE}
                                                        </Card.Text>
                                                        <Card.Text className='khmer-font-dynamic'>{(SEX == "M") ? "ប្រុស" : "ស្រី"}
                                                        </Card.Text>
                                                        <Card.Text className='khmer-font-dynamic'>{TB_BCMARKET.MARKET_KHMER_NAME}
                                                        </Card.Text>
                                                        <Card.Text className='khmer-font-dynamic'>{STORE}
                                                        </Card.Text>
                                                        <Card.Text className='khmer-font-dynamic'>{ADD_TEL}
                                                        </Card.Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                                            {/* <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 1 ,backgroundColor: 'rgb(211 213 216)',color: '#A7A9AC', margin: 0 }}></hr></Row> */}
                                            <Row className='justify-content-md-center' style={{ margin: 10, }}>
                                                <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={address} style={{ height: 30, width: 30 }} />
                                                </Col>
                                                <Col style={{ padding: 0, width: 'fit-content' }}><Card.Text className='khmer-font'>
                                                    ផ្ទះលេខ: {(HOUSE_NO == null) ? "" : HOUSE_NO}, សង្កាត់/ឃុំ: {(COMMUNE == null) ? "" : COMMUNE.C_NAME}, ស្រុក/ខណ្ឌ: {(DISTRICT == null) ? "" : DISTRICT.D_NAME}, ខេត្ត/ក្រុង: {(PROVINCE == null) ? "" : PROVINCE.PRO_NAME}
                                                </Card.Text>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                                            <Row style={{ margin: 10, }}>
                                                <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={calender} style={{ height: 30, width: 30 }} />
                                                </Col>
                                                <Col style={{ padding: 0, width: 'fit-content' }}>
                                                    <Card.Text className='khmer-font'>
                                                        {moment.parseZone(USER_CREA).locale('km').format(`ថ្ងៃ dddd, Do ខែ MMMM ឆ្នាំ YYYY, hh:mm នាទី a`)}
                                                    </Card.Text>
                                                </Col>
                                            </Row>
                                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                                            <div className="caurosel-div" style={{ padding: 5 }}>
                                                {
                                                    (this.state.openData !== false) ?
                                                        <InfiniteCarousel
                                                            dots={false}
                                                            showSides={true}
                                                            sideSize={0}
                                                            slidesToScroll={2}
                                                            slidesToShow={2}
                                                            scrollOnDevice={true}
                                                            incrementalSides={true}
                                                            cycleInterval={true}
                                                            breakpoints={[
                                                                {
                                                                    breakpoint: 500,
                                                                    settings: {
                                                                        slidesToShow: 2,
                                                                        slidesToScroll: 2,
                                                                    },
                                                                },
                                                                {
                                                                    breakpoint: 768,
                                                                    settings: {
                                                                        slidesToShow: 3,
                                                                        slidesToScroll: 3,
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            {
                                                                this.state.images.map((imageList, index) => <div>
                                                                    <img
                                                                        style={{ borderRadius: 25 }}
                                                                        class="cover narrow"
                                                                        onError={(e) => { e.target.onerror = null; e.target.src = notFound }}
                                                                        // alt={console.log(`http://51.79.251.248:7005/${imageList.IMAGE}`)}
                                                                        // alt={console.log(notFound)}
                                                                        // src={(imageList == null) ? notFound:`http://192.168.1.168:7005/${imageList.IMAGE}`}
                                                                        src={`${sever}/api/v1/imageresize?url=${imageList.IMAGE}&quality=10`}
                                                                        onClick={() => this.setState({ isOpen: true, key: index })}
                                                                    />
                                                                </div>
                                                                )
                                                            }

                                                        </InfiniteCarousel>
                                                        : <Col style={{ justifyContent: 'center', justifyItems: 'center', height: 20 }}><Spinner
                                                            as="span"
                                                            animation="border"
                                                            role="status"
                                                        /></Col>
                                                }
                                            </div>
                                        </div>
                                    </Popup>
                                </Marker>)
                            }
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </Map>
                    </div>
                    <div class="overlay" style={{ backgroundColor: "white", width: "fit-content", borderRadius: 15, height: 'fit-content' }}>
                        <Card.Text style={{ textAlign: "center", padding: 5, backgroundColor: "white", margin: 0 }} className='fix-style'>{this.state.getName}</Card.Text>
                        <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                        <Row style={{ margin: 0 }}>
                            <Card.Text style={{ textAlign: "center", padding: 10, margin: 0, backgroundColor: "white", }} className='khmer-font'>ចំនួនអតិថជន៖​</Card.Text>
                            <Card.Text style={{ textAlign: "center", padding: "10px 10px 5px 0px", margin: 0, fontFamily: 'Romnea', backgroundColor: "white", fontSize: 15 }}>{this.state.selectOptions.length} នាក់</Card.Text>
                        </Row>
                        <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                        <div style={{ padding: 10 }}>
                            <Select placeholder={"ស្វែងរកអតិថជន"} isDisabled={this.state.selectOption} options={this.state.selectOptions} onChange={this.handleChange.bind(this)} />
                        </div>
                    </div>
                    <div class="overlay">
                        <Modal className="modal-container"
                            show={this.state.noDataAlert}
                            onHide={this.onCloseModal}
                            animation={true}
                            size="sm"
                            centered
                            bsSize="small">

                            <Modal.Header>
                                <Modal.Title className={"fix-style"}>មិនមានអតិថជនទេ...</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button onClick={this.onCloseModal}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    {this.state.profileOpen &&
                        <div class="overlay">
                            <Lightbox
                                mainSrc={`${sever}/${this.state.profile}`}
                                onError={(e) => { e.target.onerror = null; e.target.src = notFound }}
                                onCloseRequest={() => this.setState({ profileOpen: false })}
                            />
                        </div>
                    }
                    {this.state.isOpen &&
                        <div class="overlay">
                            <Lightbox
                                mainSrc={(this.state.images[this.state.key].IMAGE.length == 0) ? notFound : `${sever}/${this.state.images[this.state.key].IMAGE}`}
                                nextSrc={`${sever}/${this.state.images[(this.state.key + 1) % this.state.images.length].IMAGE}`}
                                prevSrc={`${sever}/${this.state.images[(this.state.key + this.state.images.length - 1) % this.state.images.length].IMAGE}`}
                                // mainSrc={(this.state.images[this.state.key].IMAGE.length == 0) ? notFound : `http://192.168.1.168:7000/${this.state.images[this.state.key].IMAGE}`}
                                // nextSrc={`http://192.168.1.168:7000/${this.state.images[(this.state.key + 1) % this.state.images.length].IMAGE}`}
                                // prevSrc={`http://192.168.1.168:7000/${this.state.images[(this.state.key + this.state.images.length - 1) % this.state.images.length].IMAGE}`}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        key: (this.state.key + this.state.images.length - 1) % this.state.images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        key: (this.state.key + 1) % this.state.images.length
                                    })
                                }
                            />
                        </div>
                    }

                </div>
            )
        } else {
            return <LoadingScreen
                loading={true}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767'
                text='Loading data...'
            >
            </LoadingScreen>
        }
    }
}

export default withRouter(MapCustome);
