import React, { useState, setState } from 'react';
import { Map, TileLayer } from "react-leaflet";
import { Col , Row} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../img/BCLogo.png';
import openSocket from "socket.io-client";


import './login.css';

//Import image
import loginBackground from '../img/loginBackground.png'

const attributionDefault = '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>';
const urlDefault = 'https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A';
function GetAuthKey() {
  return window.localStorage.getItem('AuthBCMap') ?? '';
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#242424',
    opacity: '0.9',
    borderRadius: 25,
    padding: 50,
    borderColor: '#1aade6d9',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    // borderRadius: 25,

  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1aade6d9',
    fontStyle: 'RalwayEng',
    fontWeight: 'bold',
    padding: '10px 25px 10px 25px',
    borderRadius: 15
  },
  default: {
    backgroundColor: 'white !important',
    borderRadius: 15,
  },
  root: {
    borderWidth: "1px",
    borderStyle: 'solid',
    borderColor: '#1aade6d9 !important',
    borderRadius: 15,
  },
}));
const sever = "http://51.79.251.248:7005";
// const sever = "http://192.168.1.168:7005";
const axios = require('axios').default;
const socket = openSocket("http://51.79.251.248:7007", { reconnect: true, forceNew: true, upgrade: false, transports: ['websocket'], autoConnect: true, reconnectionDelayMax: 2000 });
// const socket = openSocket("http://192.168.1.168:7007", { reconnect: true, forceNew: true, upgrade: false, transports: ['websocket'], autoConnect: true, reconnectionDelayMax: 2000 });

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  let Login = (event) => {
    window.localStorage.clear();
    event.preventDefault();
    let url = `${sever}/api/v1/authentication/login`;
    let body = JSON.stringify({
      username: username,
      password: password
    })
    axios.post(url, body, {
      headers: {
        'Authorization': `Bearer jdkewowjs@#Dfjsdkk3er@sfdgsdfgwer231`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }).then((res) => {
      if (res.data.token != null) {
        window.localStorage.setItem('token', res.data.token);
        window.localStorage.setItem('idUser', res.data.userid);
        window.localStorage.setItem('permission', JSON.stringify(res.data.permission));
        socket.emit('global_notification', [res.data.userid]);
        window.location.reload();

        // console.log('permission', res.data)
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  return (
    <div class='row' style={{ width: "100%", height: "100vh", marginLeft: 0, overflow: 'hidden', justifyContent: 'center' }}>
      <Col class="map" style={{ padding: 0, margin: 0 }}>
        <Map zoomControl={false} center={[11.562108, 104.888535]} zoom={15}
          // minZoom={1}
          preferCanvas={true}
          zoomControl={false} style={{ height: '100vh', position: 'ralative', overflowX: 'hidden', overflowY: 'hidden' }}>
          <TileLayer
            attribution={attributionDefault}
            url={urlDefault}
            minZoom={1}
            maxZoom={19}
          />
        </Map>
      </Col>
      <div class='overlayLogin'>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper} >
            <img src={logo} style={{ width: 130, height: 130, margin: 20 }} />
            <Typography component="h1" variant="h5" style={{ color: 'white', fontStyle: 'RalwayEng', fontWeight: 'bold' }}>
              Welcome to BCMap
          </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                // autoComplete="email"
                // autoFocus
                className={classes.default}
                InputProps={{
                  className: classes.root,
                  classes: {
                    root: classes.root,
                    disabled: classes.disabled,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                onKeyUp={(event) => setUsername(event.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                className={classes.default}
                InputProps={{
                  className: classes.root,
                  classes: {
                    root: classes.root,
                    disabled: classes.disabled,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                // autoComplete="current-password"
                onKeyUp={(event) => setPassword(event.target.value)}
              />
              <Row className='justify-content-md-center'>
                <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(event) => Login(event)}
                >
                  LogIn
      </Button>
              </Row>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
}
