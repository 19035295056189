import React from "react";
import {Router} from "react-router-dom";
import moment from 'moment';
import "moment/locale/en-gb";
import history from "./service/history";
import Routes from "./routes";

function App() {
  moment.locale("en-gb")
  return(
    <Router history={history}>
      <Routes/> 
    </Router>
  )
}

export default App;