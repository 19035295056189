import React, { Component, Fragment } from "react";
import { Map, TileLayer, Marker, Polyline, Popup, FeatureGroup } from "react-leaflet";
import { Card, Button, Row, Container, Col, Spinner, ListGroup, Modal } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import LoadingScreen from 'react-loading-screen';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';
import moment from 'moment';
import "moment/locale/en-gb";
import TimeKeeper from 'react-timekeeper';
import MarkerClusterGroup from "react-leaflet-markercluster";
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { FaPrint, FaTrash, FaArrowAltCircleRight, FaLocationArrow, FaCar, FaCalendarDay, FaCalculator } from 'react-icons/fa';
import { IconContext } from "react-icons";

//Import Image Asset
import notFound from '../img/NotFound.png';
import print from '../img/print.png';
import trash from '../img/trash.png';
import address from '../img/address.png';
import calender from '../img/calender.png';
import speed from '../img/speed.png';

//Import Map Logo
import map from '../img/map.png';
import openStreetMap from '../img/OpenStreetMap.png';
import HumannitarainMap from '../img/HumanitarainMap.png';
import darkMap from '../img/darkMap.jpg';
import wikimedia from '../img/WikimediaMap.png';
import sattlelite from "../img/sattelite.jpeg";
import HikeBikeMap from "../img/HikeBikeMap.png";
import whiteMap from "../img/whiteMap.png";
import fiord from '../img/fiord.jpg';
import grayMap from '../img/grayMap.png';
import modern from '../img/modern.png';

const sever = "http://51.79.251.248:7005";
// const sever = "http://192.168.1.168:7005";

// const severPrint = "http://192.168.1.168:7005";
const severPrint = "http://51.79.251.248:7006";

const attributionDefault = '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>';
const urlDefault = 'https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A';

class MapComponent extends React.Component {
    constructor() {
        super();
        this.showDetail = this.showDetail.bind(this);
    }

    state = {
        lat: 11.562108,
        lng: 104.888535,
        zoom: 15,
        isMapInit: false,
        SaleName: "",
        datamap: [],
        startDate: moment(),
        endDate: moment(),
        focusedInput: null,
        isloading: true,
        loadingData: false,
        showStartTime: false,
        showEndTime: false,
        startTime: '00:00',
        endTime: '23:59',
        display_name: "",
        speed: "",
        newArray: [],
        postCode: "",
        name: "",
        countData: 0,
        newAdded: false,
        DataSet: {},
        noDataAlert: false,
        ClearedDataAlert: false,
        addedReport: true,
        clearData: true,
        userPhone: "",
        type: "",
        userImage: '',
        openPanelRightMap: false,
        attribution: attributionDefault,
        urlMap: urlDefault,
        colorMarker: "#1aade6d9",
        marginCard: '10px 50px 0px 0px',
        opacity: '0.9',
        colorsByMap: "#242424",
        borderColorByMap: "#1aade6d9",
        textColorByMap: 'white',
        lineColorByMap: '#1aade6d9',
        marginRightDetail: '2%'
    };


    componentDidMount() {
        moment.locale("en-gb")
        const { id } = this.props.match.params;
        var getToken = window.localStorage.getItem('token');
        const axios = require('axios').default;
        let url = `${sever}/api/v1/user/getuserbyid?userid=${id}`;
        // let url = `http://192.168.1.168:7005/api/v1/user/getuserbyid?userid=${id}`;
        axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + getToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            console.log("Data", res.data)
            document.title = `${res.data.last_name} ${res.data.first_name} Detail`;
            const SaleName = `${res.data.last_name} ${res.data.first_name}`;
            const userPhone = res.data.phonenumber;
            const userImage = res.data.image;
            const type = res.data.type;
            this.setState({ SaleName, userPhone, type, isloading: false, userImage })
            console.log("DataUser", res.data)
        }).catch((err) => {
            console.log("AXIOS ERROR: ", err);
        });
    }

    handleFocusChange = focusedInput => {
        console.log(focusedInput);
        this.setState({ focusedInput });
        console.log(
            "StartDay" + this.state.startDate,
            "EndDay" + this.state.endDate
        );
    };

    handleDateChange = ({ startDate, endDate }) =>
        this.setState({ startDate, endDate });

    isOutsideRange = day =>
        day.isAfter(moment()) || day.isBefore(moment().subtract(30, "days"));

    _getAPISaler = (e) => {
        const newArray = [];
        const addedReport = true;
        this.setState({
            loadingData: true,
            newArray, addedReport, clearData: true
        })

        var startDay = moment(this.state.startDate).format(`YYYY-MM-DD[T]`);
        var endDay = moment(this.state.endDate).format("YYYY-MM-DD[T]");

        var startTimeConvert;
        var endTimeConvert;
        console.log(this.state.startTime.length, this.state.endTime.length)
        if (this.state.startTime.length === 4) {
            startTimeConvert = 0 + this.state.startTime
        } else {
            startTimeConvert = this.state.startTime
        }
        if (this.state.endTime.length === 4) {
            endTimeConvert = 0 + this.state.endTime
        } else {
            endTimeConvert = this.state.endTime
        }

        const { id } = this.props.match.params;
        var getToken = localStorage.getItem('token');
        const axios = require('axios').default;
        let url = `${sever}/api/v1/userlocation?fromdate=${startDay}${startTimeConvert}:00.000&todate=${endDay}${endTimeConvert}:00.000&userid=` + id;
        // let url = `http://192.168.1.168:7005/api/v1/userlocation?fromdate=${startDay}${startTimeConvert}:00.000&todate=${endDay}${endTimeConvert}:00.000&userid=` + id;
        console.log(url);
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getToken}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            console.log("Get data from API", res.data.length);
            const datamap = res.data;
            var nodata = ""
            if (res.data.length == 0) {
                nodata = true;
            }
            const isloading = false;
            this.setState({ datamap, isloading, noDataAlert: nodata, loadingData: false });
        }).catch((err) => {
            this.setState({
                noDataAlert: true,
                loadingData: false
            })
        });
    }

    SendReport() {
        this.setState({
            loadingData: true
        })
        const saleData = {
            name: this.state.SaleName,
            phone: this.state.userPhone,
            createdate: moment.parseZone(Date.now()).format(`DD/MM/YYYY, hh:mm a`)
        }
        var DataSet = { sale: saleData, items: this.state.newArray, }
        console.log("DataReport", this.state.newArray)

        let url = `${severPrint}/api/report`;
        // let url = 'http://192.168.1.168:7006/api/report';

        const axios = require('axios').default;
        axios.post(url, {
            template: {
                shortid: 'rJl0Rxcwbw',
            },
            data: DataSet,
        }, {
            'headers': {
                'Authorization': 'Basic YWRtaW46QmNAZG1pbg==',
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'blob',
            responseEncoding: 'utf8'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report.pdf`);
            document.body.appendChild(link);
            this.setState({ loadingData: false })
            link.click();
        }).catch(err => console.error(err));
    }

    onCloseModal = () => {
        this.setState({ noDataAlert: false, ClearedDataAlert: false });
    };

    polylineLineMaker() {
        const geojsonDataByLines = this.state.datamap;
        const polyLine = geojsonDataByLines.map((cord) => {
            return [cord.LATITUDE, cord.LONGITUDE];
        });
        return polyLine;
    }

    clearDateSelection = () => {
        this.setState({
            startDate: null,
            endDate: null
        });
    };

    showDetail = e => {
        console.log("DataRes", e.layer);
        console.log("DataRes", e.layer._zoom);

        var getLat = e.layer.getLatLng();
        const axios = require('axios').default;
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${getLat.lat}&lon=${getLat.lng}&accept-language=kh`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            if (res.status == 200) {
                var displayMap = this.state.newArray.find((e) => e.desc === res.data.display_name)
                if (displayMap == null) {
                    if (e.layer._childCount != null) {
                        const display_name = res.data.display_name;
                        const countData = e.layer.getChildCount();
                        this.setState({ display_name, countData })
                    } else if (e.layer._childCount == null) {
                        console.log("Third Loop")
                        const display_name = res.data.display_name;
                        const countData = 1;
                        this.setState({ display_name, countData })
                    }
                    var datestart = moment.parseZone(this.state.startDate).format(`DD/MM/YYYY`);
                    var dateend = moment.parseZone(this.state.endDate).format(`DD/MM/YYYY`);
                    console.log("DataDisplay", this.state.display_name)
                    if (this.state.display_name != null) {
                        const obj = {
                            desc: this.state.display_name,
                            count: this.state.countData,
                            date: `${datestart} ដល់​ ${dateend}`
                        };
                        const newArray = this.state.newArray;
                        newArray.push(obj);
                        this.setState({ newArray, clearData: false, addedReport: false, });
                    }
                }
            }
        }).catch((err) => {
            console.log("AXIOS ERROR: ", err);
        });
    }

    _clearData() {
        const newArray = [];
        const addedReport = true;
        this.setState({ newArray, addedReport, ClearedDataAlert: true, clearData: true })
    }

    render() {
        const { lat, lng, zoom, } = this.state;
        const position = [lat, lng];

        const styles = {
            card: {
                borderRadius: 55,
            },
            cardImage: {
                height: 150,
                width: 150,
                backgroundColor: 'white',
                position: 'cover'
            },
            circleStyle: {
                padding: 10,
                margin: 20,
                display: "inline-block",
                backgroundColor: "white",
                borderRadius: "50%",
                width: 100,
                height: 100,
            }
        }
        var endMarker = this.state.datamap[this.state.datamap.length - 1];
        var firstMarker = this.state.datamap[0];
        if (this.state.isloading != true) {
            return (
                <div>
                    <div class='row' style={{ width: "100%", height: "100vh", marginLeft: 0, overflow: 'hidden' }}>
                        <Col class="map" style={{ padding: 0, margin: 0 }}>
                            <Map className="markercluster-map" zoomControl={false} center={position} zoom={zoom}
                                // minZoom={1}
                                preferCanvas={true}
                                zoomControl={false} ref={this.saveMap} style={{ height: '100vh', position: 'ralative', overflowX: 'hidden', overflowY: 'hidden' }}>
                                <TileLayer
                                    attribution={this.state.attribution}
                                    url={this.state.urlMap}
                                    minZoom={1}
                                    maxZoom={19}
                                />
                                <Col>
                                    <Button className='overlayButtonAreaLayout' style={{ backgroundColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", color: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", borderColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0" }}
                                        onClick={() => this.setState({ openPanelRightMap: !this.state.openPanelRightMap, openPanelRightNoti: false, marginRightDetail: (this.state.openPanelRightMap) ? "2%" : "18%" })}
                                    >
                                        <img src={map} style={{ width: 20, height: 20 }} />
                                    </Button>
                                </Col>
                                {
                                    this.state.datamap.length != 0 && <MarkerClusterGroup
                                        showCoverageOnHover={false}
                                        wrapperOptions={{ enableDefaultStyle: true }}
                                        // onClick={(cluster) => console.log("DataFromCluster", cluster.layer.getLatLng())} latlng
                                        // onClick={(cluster) => console.log("DataFromCluster", cluster.layer.getChildCount(), cluster.layer.getLatLng())}
                                        // onClick={(cluster) => console.log("DataFromCluster", (cluster.layer._childCount == null) ? "0" : cluster.layer._childCount)} for if Else
                                        onClick={(cluster) => this.showDetail(cluster)}
                                    >
                                        {
                                            this.state.datamap.map(({ ID, LATITUDE, LONGITUDE, SPEED, CREATED_DATE, USERID, BEARING, USER }, index) =>
                                                (
                                                    <Marker
                                                        key={ID}
                                                        ref={ID, LATITUDE, LONGITUDE, SPEED, CREATED_DATE, USERID, BEARING, USER}
                                                        // onClick={() => this.showDetail({ LATITUDE, LONGITUDE, ID, SPEED, CREATED_DATE })}
                                                        position={[LATITUDE, LONGITUDE]}
                                                        icon={
                                                            (firstMarker.ID == ID) ? L.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: "<div style='background-color:green;' class='marker-pin'><div class='overlayMarker' ><p class='pStyle' style='position: absolute;'>ចាប់ផ្តើម</p></div></div>" + `<img class='awesome' src='${sever}/api/v1/imageresize?url=${this.state.userImage}&quality=5' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                                iconSize: [30, 42],
                                                                iconAnchor: [15, 42]
                                                            }) : (endMarker.ID == ID) ? L.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: "<div style='background-color:red;' class='marker-pin'><div class='overlayMarker'><p class='pStyle' style='position: absolute;'>បញ្ចប់</p></div></div>" + `<img class='awesome' src='${sever}/api/v1/imageresize?url=${this.state.userImage}&quality=5' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                                iconSize: [30, 42],
                                                                iconAnchor: [15, 42]
                                                            }) : L.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: "<div style='background-color:blue;' class='marker-pin'><div class='overlayListMarker'><p class='pStyle' style='position: absolute;'>" + index + "</p></div></div>" + `<img class='awesome' src='${sever}/api/v1/imageresize?url=${this.state.userImage}&quality=5' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                                iconSize: [30, 42],
                                                                iconAnchor: [15, 42]
                                                            })
                                                        }>
                                                        <Popup closeButton={false}>
                                                            <div style={{ backgroundColor: this.state.colorsByMap, borderRadius: 25, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.borderColorByMap, opacity: this.state.opacity }}>
                                                                <div style={styles.styleCard}>
                                                                    <Row style={{ justifyContent: 'center', }}>
                                                                        <div class="avatar-flip" style={{ backgroundColor: this.state.colorsByMap }}>
                                                                            <img style={styles.cardImage} variant="top" class='cover' onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${sever}/api/v1/imageresize?url=${this.state.userImage}&quality=20`} />
                                                                            {/* <Card.Img variant="top" class='cover' src={(PICTURE.length == 0) ? notFound : `http://192.168.1.168:7000/${d.USER.image}`} style={styles.cardImage} /> */}
                                                                        </div>
                                                                    </Row>
                                                                    <Col style={{ justifyContent: 'center', textAlign: "center", marginTop: '75px' }}>
                                                                        <Card.Title style={{ fontSize: '20px', fontFamily: 'RalwayEng', color: this.state.textColorByMap }}>{this.state.SaleName}</Card.Title>
                                                                        <Container style={{ backgroundColor: "#27AAE1", width: 'fit-content', borderRadius: 9, padding: '0px 6px 0px 5px' }}>
                                                                            <Card.Title style={{ color: this.state.textColorByMap, padding: '4px 1px 4px 1px', fontSize: '15px', fontFamily: 'RalwayEng', fontWeight: "bold !important" }}>
                                                                                {this.state.type}
                                                                            </Card.Title>
                                                                        </Container>
                                                                    </Col>
                                                                </div>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                                <div style={{ padding: 10, display: "inline-block", justifyContent: 'center' }}>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={address} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        {
                                                                            (this.state.display_name.length != 0) ? <Col style={{ paddingLeft: 0, width: 'fit-content' }}><Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                                {this.state.display_name}
                                                                            </Card.Text></Col> : <Col style={{ justifyContent: 'center', justifyItems: 'center' }}>
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        animation="grow"
                                                                                        size="sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    /></Col>
                                                                        }
                                                                    </Row>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={speed} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        <Col style={{ width: 'fit-content', display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
                                                                            <Card.Text className='khmer-font' style={{ paddingTop: 10, color: this.state.textColorByMap }}>
                                                                                ល្បឿន៖ {SPEED > 0 ? (SPEED * 1.60934).toString().slice(0, 5) : 0} Km/h
                                                                                    </Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={calender} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        <Col style={{ paddingLeft: 0, width: 'fit-content' }}>
                                                                            <Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                                {moment.parseZone(CREATED_DATE).locale('km').format(`ថ្ងៃ dddd, Do ខែ MMMM ឆ្នាំ YYYY, hh:mm នាទី a`)}
                                                                            </Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Popup>
                                                    </Marker>
                                                )
                                            )
                                        }
                                    </MarkerClusterGroup>
                                }
                                <Polyline positions={this.polylineLineMaker()} color={"blue"}></Polyline>
                            </Map>
                            {
                                this.state.loadingData && <LoadingScreen
                                    loading={true}
                                    bgColor='#f1f1f1'
                                    spinnerColor='#9ee5f8'
                                    textColor='#676767'
                                    text='Loading data...'
                                >
                                </LoadingScreen>
                            }
                        </Col>
                        {
                            this.state.openPanelRightMap && <Col md={2} style={{ backgroundColor: "#F0F0F0", width: 'fit-content', height: "100%", padding: 10 }}>
                                <Row style={{ margin: 10, justifyContent: 'start' }}>
                                    <Card.Title style={{ fontFamily: 'Romnea', fontSize: 18, padding: 10 }}>
                                        ជ្រើសរើសផែនទី
                            </Card.Title>
                                </Row>
                                <div onClick={() =>
                                    this.setState({ attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទីដើម</Row>
                                        <Row>
                                            <img src={openStreetMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() =>
                                    this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '`&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`', urlMap: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Humannitarain</Row>
                                        <Row>
                                            <img src={HumannitarainMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ colorMarker: '#cefcb8', attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community', urlMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី Sattelite</Row>
                                        <Row>
                                            <img src={sattlelite} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ colorMarker: '#289812', attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី HikeBikeMap</Row>
                                        <Row>
                                            <img src={HikeBikeMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទីខ្មៅ</Row>
                                        <Row>
                                            <img src={darkMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13,
                                        }}>ផែនទី Modern</Row>
                                        <Row>
                                            <img src={modern} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://maps.targomo.com/styles/fiord-color-gl-style/rendered/{z}/{x}/{y}.png" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13,
                                        }}>ផែនទី FiordColor</Row>
                                        <Row>
                                            <img src={fiord} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: 'https://maps.targomo.com/styles/gray-gl-style/rendered/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី Grey</Row>
                                        <Row>
                                            <img src={grayMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                                <div onClick={() => this.setState({ attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: 'black'
                                        }}>ផែនទី ស</Row>
                                        <Row>
                                            <img src={whiteMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>

                            </Col>
                        }
                    </div>
                    <div class="overlay">
                        <Modal className="modal-container"
                            show={this.state.noDataAlert}
                            onHide={this.onCloseModal}
                            animation={true}
                            size="sm"
                            centered
                            bsSize="small">
                            <Modal.Header style={{backgroundColor: this.state.colorsByMap}}>
                                <Modal.Title style={{color: this.state.textColorByMap}}>No Data...</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer style={{backgroundColor: this.state.colorsByMap}}>
                                <Button onClick={this.onCloseModal}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div class="overlay">
                        <Modal className="modal-container"
                            show={this.state.ClearedDataAlert}
                            onHide={this.onCloseModal}
                            animation={true}
                            size="sm"
                            centered
                            bsSize="small">

                            <Modal.Header>
                                <Modal.Title>Data Cleared...!</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button onClick={this.onCloseModal}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div class="overlayDetail" style={{ backgroundColor: this.state.colorsByMap, height: 'fit-content', width: 'auto', borderRadius: "15px", marginRight: this.state.marginRightDetail, opacity: this.state.opacity }}>
                        <div class="panel-body" style={{ width: 'fit-content', borderRadius: "15px", backgroundColor: this.state.colorsByMap }}>
                            <Card class="card" style={{ width: '18rem', display: 'flex', borderRadius: "15px", backgroundColor: this.state.colorsByMap, opacity: this.state.opacity }}>
                                <Row style={{ justifyContent: 'center', }}>
                                    <div class="avatar-flipDetail" style={{ backgroundColor: this.state.colorsByMap }}>
                                        <img style={styles.cardImage} variant="top" class='cover' onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${sever}/api/v1/imageresize?url=${this.state.userImage}&quality=50`} />
                                        {/* <Card.Img variant="top" class='cover' src={(PICTURE.length == 0) ? notFound : `http://192.168.1.168:7000/${d.USER.image}`} style={styles.cardImage} /> */}
                                    </div>
                                </Row>
                                {/* <Card.Img variant="top" class="card-img-top" onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${getImage}`} style={styles.cardImage} alt="Card image cap" /> */}
                                <Col style={{ justifyContent: 'center', textAlign: "center", marginTop: '75px' }}>
                                    <Card.Text style={{ textAlign: 'center', fontFamily: "RalwayEng", fontSize: 20, paddingTop: 5, color: this.state.textColorByMap }}>
                                        {this.state.SaleName}
                                    </Card.Text>
                                    <Container style={{ backgroundColor: '#27AAE1', width: 'fit-content', borderRadius: 9, padding: '0px 6px 0px 5px' }}>
                                        <Card.Title style={{ color: 'white', padding: '4px 1px 4px 1px', fontSize: '15px', fontFamily: 'RalwayEng', fontWeight: "bold !important" }}>
                                            {this.state.type}
                                        </Card.Title>
                                    </Container>
                                </Col>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                <div class='col item-sidebar' style={{ display: 'flex', justifyContent: 'start' }} disabled={this.state.addedReport} onClick={() => this.SendReport()}>
                                    <Row style={{ margin: 10 }} className="align-content-md-center">
                                        {/* <Button className={'khmer-font'} onClick={(e) => { this.handleEntailmentRequest(e) }} variant="outline-primary" > */}
                                        <img src={print} style={{ height: 30, width: 30, marginRight: 10 }} />
                                        <div class='col' style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ color: this.state.textColorByMap, fontSize: '15px', fontFamily: 'RalwayEng', textAlign: 'center', paddingTop: "3px", paddingLeft: 10 }}>Print</h5>
                                        </div>
                                    </Row>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>

                                <div class='col item-sidebar' style={{ display: 'flex', justifyContent: 'start' }} disabled={this.state.clearData} onClick={() => this._clearData()}>
                                    <Row style={{ margin: 10 }} className="align-content-md-center">
                                        {/* <Button className={'khmer-font'} onClick={(e) => { this.handleEntailmentRequest(e) }} variant="outline-primary" > */}
                                        <img src={trash} style={{ height: 30, width: 30, marginRight: 10 }} />
                                        <div class='col' style={{ display: 'flex', alignItems: 'center' }}>
                                            <h5 style={{ color: this.state.textColorByMap, fontSize: '15px', textAlign: 'center', fontFamily: 'RalwayEng', paddingTop: "3px", paddingLeft: 9 }}>Clear</h5>
                                        </div>
                                    </Row>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                {
                                    this.state.newArray.length != 0 &&
                                    <ListGroup variant="flush" className="list-group, listScroll" style={{ margin: "0px 15px 10px 15px", overflowX: 'hidden', border: 0 }}>
                                        {
                                            this.state.newArray.map(({ count, desc, date }) => (
                                                <Card style={{ padding: 5, borderRadius: 15, marginTop: 10, backgroundColor: this.state.colorsByMap, borderColor: this.state.lineColorByMap }} className='khmer-font'>
                                                    {/* <ListGroup.Item class="list-group-item">ចំនួនដង ៖ {count} ដង</ListGroup.Item>
                                                    <ListGroup.Item class="list-group-item">ទីតាំងបានទៅ ៖ {desc}</ListGroup.Item>
                                                    <ListGroup.Item class="list-group-item">ថ្ងៃខែឆ្នាំ ៖ {date}</ListGroup.Item> */}
                                                    <div style={{ display: 'flex', alignItems: 'start' }}>
                                                        <h5 style={{ color: this.state.textColorByMap, lineHeight: 2, fontSize: '15px', fontFamily: 'Content', margin: 0, padding: 10 }}>ចំនួន ៖ {count} ដង</h5>
                                                    </div>
                                                    <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                    <div style={{ display: 'flex', alignItems: 'start' }}>
                                                        <h5 style={{ color: this.state.textColorByMap, lineHeight: 2, fontSize: '15px', fontFamily: 'Content', margin: 0, padding: 10 }}>ទីតាំងបានទៅ ៖ {desc}</h5>
                                                    </div>
                                                    <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                                    <div style={{ display: 'flex', alignItems: 'start' }}>
                                                        <h5 style={{ color: this.state.textColorByMap, lineHeight: 2, fontSize: '15px', fontFamily: 'Content', margin: 0, padding: 10 }}>ថ្ងៃខែឆ្នាំ ៖ {date}</h5>
                                                    </div>
                                                </Card>
                                            )
                                            )
                                        }
                                    </ListGroup>
                                }
                            </Card>
                        </div>
                    </div>
                    <div class="overlay" style={{ backgroundColor: this.state.colorsByMap, borderRadius: 25, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.borderColorByMap, opacity: this.state.opacity }}>
                        <DateRangePicker
                            showClearDates={true}
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            focusedInput={this.state.focusedInput}
                            isOutsideRange={() => null}
                            onDatesChange={this.handleDateChange}
                            onFocusChange={this.handleFocusChange}
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            renderMonthElement={renderMonthElement}
                            singleDateRange={true}
                            minimumNights={0}
                            displayFormat={"DD/MM/YYYY"}
                            style={{color: this.state.textColorByMap, backgroundColor: this.state.colorsByMap}}
                        />
                        {
                            !this.state.focusedInput &&
                            <Col>
                                <Container style={{ backgroundColor: this.state.colorsByMap, marginTop: '10px', marginLeft: 20, padding: '10px', posistion: 'relative' }}>
                                    <Row>
                                        <Col>
                                            <div class="overlay">
                                                {
                                                    this.state.showStartTime && <TimeKeeper time={this.state.startTime}
                                                        onChange={(StartNew) => this.setState({ startTime: StartNew.formatted24 })}
                                                        onDoneClick={() => this.setState({ showStartTime: false })}
                                                        forceCoarseMinutes
                                                    />
                                                }
                                            </div>
                                            <div onClick={() => this.setState({ showStartTime: true, showEndTime: false })} style={{color: this.state.textColorByMap}} ><span>Start {this.state.startTime}</span></div>

                                        </Col>
                                        {!this.state.showStartTime &&
                                            <Col>
                                                <div class="overlay">
                                                    {
                                                        this.state.showEndTime &&
                                                        <TimeKeeper time={this.state.endTime}
                                                            onChange={(EndNew) => this.setState({ endTime: EndNew.formatted24 })}
                                                            onDoneClick={() => this.setState({ showEndTime: false })}
                                                            style={{color: this.state.colorsByMap}}
                                                            forceCoarseMinutes
                                                        />
                                                    }
                                                </div>
                                                <div onClick={() => this.setState({ showEndTime: true, showStartTime: false })} style={{color: this.state.textColorByMap}} ><span>End {this.state.endTime}</span></div>
                                            </Col>
                                        }
                                    </Row>
                                </Container>
                                <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                    {
                                        !this.state.showStartTime && !this.state.showEndTime && <Button style={{
                                            width: "100px",
                                            height: "35px"
                                        }} onClick={() => this._getAPISaler()}>Go <IconContext.Provider value={{ style: { fontSize: '13px' } }}>
                                                <FaArrowAltCircleRight />
                                            </IconContext.Provider></Button>
                                    }
                                </Col>
                            </Col>
                        }

                    </div>

                </div >
            )
        } else {
            return <LoadingScreen
                loading={true}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767'
                text='Loading data...'
            >
            </LoadingScreen>
        }
    }
}
const returnYears = () => {
    let years = [];
    for (
        let i = parseInt(moment(min).format("YYYY"));
        i <= parseInt(moment(max).format("YYYY"));
        i++
    ) {
        years.push(<option value={i}>{i}</option>);
    }
    return years;
};
const
    min = "2000-07-20",
    max = "2022-12-30";

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
                <select
                    value={
                        moment(month).isBefore(moment(min))
                            ? moment(min).month()
                            : month.month()
                    }
                    onChange={e => {
                        if (month.isBefore(moment(min))) {
                            onMonthSelect(moment(min), moment(min).month());
                        } else {
                            onMonthSelect(month, e.target.value);
                        }
                    }}
                >
                    {moment.months().map((label, value) => (
                        <option value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select
                    value={month.year()}
                    onChange={e => {
                        onYearSelect(month, e.target.value);
                    }}
                >
                    {returnYears()}
                </select>
            </div>
        </div>
    );
};


export default withRouter(MapComponent);