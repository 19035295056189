import React, { useState } from 'react';
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import './styles.css';
import '../index.js';
import Leaflet, { LatLng, marker } from 'leaflet';
import AwesomeMarkers from 'leaflet.awesome-markers';
import LoadingScreen from 'react-loading-screen';
import { Button, Card, Spinner, Row, Col, Collapse, Container, Modal } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
// import "moment/min/locales";
import { Multiselect } from 'multiselect-react-dropdown';
import notFound from '../img/NotFound.png';
import { FaInfo, FaUsers, FaLocationArrow, FaCar, FaCalendarDay, FaSync, FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { IconContext } from "react-icons/"
import openSocket from "socket.io-client";
import "leaflet/dist/leaflet.css";
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Lightbox from 'react-image-lightbox';
import _, { forEach } from 'lodash'


//Image insert

import logo from '../img/BCLogo.png';
import profile from '../img/userOnlineprofile.png';
import address from '../img/address.png';
import calender from '../img/calender.png';
import customerLogo from '../img/customer.png';
import detail from '../img/detail.png';
import speed from '../img/speed.png';
import refresh from '../img/refresh.png';
import openStreetMap from '../img/OpenStreetMap.png';
import HumannitarainMap from '../img/HumanitarainMap.png';
import darkMap from '../img/darkMap.jpg';
import wikimedia from '../img/WikimediaMap.png';
import sattlelite from "../img/sattelite.jpeg";
import HikeBikeMap from "../img/HikeBikeMap.png";
import whiteMap from "../img/whiteMap.png";
import bell from '../img/bell.png';
import map from '../img/map.png';
import cancel from '../img/cancel.png';
import fiord from '../img/fiord.jpg';
import grayMap from '../img/grayMap.png';
import modern from '../img/modern.png';
import {Battery} from '@pxblue/react-progress-icons';


Leaflet.Icon.Default.imagePath =
    '../node_modules/leaflet'
delete Leaflet.Icon.Default.prototype._getIconUrl;
// const socket = openSocket("http://192.168.1.168:7007", { reconnect: true, forceNew: true, upgrade: false, transports: ['websocket'], autoConnect: true, reconnectionDelayMax: 2000 });
const socket = openSocket("http://51.79.251.248:7007", { reconnect: true, forceNew: true, upgrade: false, transports: ['websocket'], autoConnect: true, reconnectionDelayMax: 2000 });

const sever = "http://51.79.251.248:7005";
// const sever = "http://192.168.1.168:7005";

const attributionDefault = '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>';
const urlDefault = 'https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A';
let appStart = false;
const token = window.localStorage.getItem('token');
const idUser = window.localStorage.getItem('idUser');
class MapHome extends React.Component {
    intervalID;
    constructor(props) {
        super(props);
    }
    state = {
        lat: 11.562108,
        lng: 104.888535,
        zoom: 13,
        image: "",
        name: "",
        loginID: "",
        refreshData: false,
        notifcationData: [],
        dataMap: [],
        markers: [],
        userID: [],
        lengthData: [],
        polyLineMarkers: [],
        markersRefresh: [],
        markersLoop: [],
        dataUserMarker: [],
        display_name: "",
        seller: false,
        showButton: true,
        loadingData: true,
        selectOptions: [],
        type: "",
        selectTypeUser: [],
        selectUserbyType: [],
        noDataAlert: false,
        selectUserType: true,
        mapLoading: true,
        onlineUsers: [],
        openPanelLeft: true,
        openPanelRightNoti: false,
        openPanelRightMap: false,
        openPanelBottom: false,
        firstEvent: false,
        secondEvent: false,
        attribution: attributionDefault,
        urlMap: urlDefault,
        AreabuttonColor: 'black',
        RefreshbuttonColor: '#1aade6d9',
        CustomerSell: false,
        profileOpen: false,
        profile: '',
        opacity: '0.9',
        colorsByMap: "#242424",
        borderColorByMap: "#1aade6d9",
        textColorByMap: 'white',
        lineColorByMap: '#1aade6d9',
        userOnlineSorted: [],
        clientBatery: [],
        operationPermission: false,
        webUserOnlinePermission: false,
        webCustomerLocationPermission: false,
        grant: '',
        countUserOnline: 0
    }

    static contextTypes = {
        router: PropTypes.object
    }

    async componentDidMount() {
        socket.emit('global_notification', [idUser]);

        this.getDataFromToken();
        this.intervalID = setInterval(() => {
            if (appStart) {
                // console.debug('AppStart jol hery', appStart)
                this.getDataLatLngPolyLine.bind(this)
            }
        },
            50
        );

        socket.on('connect', (_) => {
            
            socket.on("online_client", data => {
                this.setState({ onlineUsers: data })
                this._usersOnlineStatus();
            });

            socket.on("global_notification_loginout", dataLoginNotification => {
                // console.log('LoginNotification', dataLoginNotification)

                function MyNotification() {
                    return (
                        <div style={{
                            display: 'flex',
                            backgroundColor: '#28a745',
                            borderRadius: 5,
                            padding: 5,
                            height: 'fit-content'
                        }}>
                            <Row className='justify-content-md-center' style={{ margin: 0, width: '-webkit-fill-available', padding: '0px 5px 0px 5px' }}>
                                <Col xs={3} sm={3} md={3} lg={3} style={{ display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'center' }}>
                                    <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={{
                                        height: 50,
                                        width: 50,
                                        borderRadius: '50%',
                                        position: 'cover'
                                    }} src={`${sever}/api/v1/imageresize?url=${dataLoginNotification.profile}&quality=30`} />
                                </Col>
                                <Col style={{ padding: '0px 10px 0px 10px', width: '-webkit-fill-available' }}>
                                    <Row>
                                        <Col className='align-items-start' style={{ padding: '5px 0px 10px 10px' }}>
                                            <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'white', fontSize: 15, maxLines: 2 }}>{`${dataLoginNotification.desc}`}</div>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0 }}></hr></Row>
                                    <Row>
                                        <Col className='align-items-start'>
                                            <div style={{ textAlign: 'start', fontFamily: 'Romnea', fontSize: 13, color: 'white' }}>{`${dataLoginNotification.name}`}</div>
                                        </Col>
                                        <Col xs={5} sm={5} md={5} lg={5} className='align-items-center' style={{ padding: '0px' }}>
                                            <div style={{ textAlign: 'start', fontFamily: 'RalwayEng', fontSize: 12, color: 'white', fontWeight: 'bold' }}>{`${moment(dataLoginNotification.date).fromNow(true)}`}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )
                }
                store.addNotification({
                    // title: `${dataNotification.desc}`,
                    // message: `${dataNotification.name}`,
                    content: MyNotification,
                    // type: "awesome",
                    container: 'bottom-right',
                    animationIn: ["animated", "zomeIn"],
                    animationOut: ["animated", "zoomOut"],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true
                    }
                })
            })
            socket.on("global_notification", dataNotification => {
                // console.log('GlobalNotification', dataNotification)
                function MyNotification() {
                    return (
                        (!dataNotification.trans_ref) ?
                            <div style={{
                                display: 'flex',
                                backgroundColor: 'rgba(240,240,240,1)',
                                borderRadius: 25,
                                padding: 5,
                                height: 'fit-content',
                                boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px',
                            }}>
                                <Row className='justify-content-md-center' style={{ margin: 0, width: '-webkit-fill-available', padding: '0px 5px 0px 5px' }}>
                                    <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'center' }}>
                                        <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={{
                                            height: 60,
                                            width: 60,
                                            borderRadius: '50%',
                                            position: 'cover',
                                            boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px'

                                        }} src={`${sever}/api/v1/imageresize?url=${dataNotification.profile}&quality=50`} />
                                    </Col>
                                    <Col style={{ padding: '0px 10px 0px 10px', width: '-webkit-fill-available' }}>
                                        <Row>
                                            <Col className='align-items-start' style={{ padding: '5px 0px 10px 15px', margin: 0 }}>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: 'black', fontSize: 15, maxLines: 2 }}>{`${dataNotification.desc}​`}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`លេខកូដ`}</div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                            </Col>
                                            <Col className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${dataNotification.code}`}</div>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>
                                        <Row>
                                            <Col className='align-items-start'>
                                                <div style={{ textAlign: 'start', fontFamily: 'Romnea', fontSize: 13, color: 'black' }}>{`${dataNotification.name}`}</div>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-center' style={{ padding: '0px' }}>
                                                <div style={{ textAlign: 'start', fontFamily: 'Content', fontSize: 12, fontWeight: 'bold', color: '#27AAE1' }}>{`${moment(dataNotification.date).fromNow()}`}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div style={{
                                display: 'flex',
                                backgroundColor: 'rgba(240,240,240,1)',
                                borderRadius: 25,
                                padding: 5,
                                height: 'fit-content',
                                boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px',
                            }}>
                                <Row className='justify-content-md-center' style={{ margin: 0, width: '-webkit-fill-available', padding: '0px 5px 0px 5px' }}>
                                    <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'center' }}>
                                        <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={{
                                            height: 60,
                                            width: 60,
                                            borderRadius: '50%',
                                            position: 'cover',
                                            boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px'

                                        }} src={`${sever}/api/v1/imageresize?url=${dataNotification.profile}&quality=50`} />
                                    </Col>
                                    <Col style={{ padding: '0px 10px 0px 10px', width: '-webkit-fill-available' }}>
                                        <Row>
                                            <Col className='align-items-start' style={{ padding: '5px 0px 10px 15px', margin: 0 }}>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: 'black', fontSize: 15, maxLines: 2 }}>{`${dataNotification.desc}​`}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`លេខវិក័យបត្រ`}</div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                            </Col>
                                            <Col className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${dataNotification.trans_ref}`}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`ផ្សារ`}</div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                            </Col>
                                            <Col>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${dataNotification.market}`}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`តូបលេខ`}</div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                            </Col>
                                            <Col>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${dataNotification.store}`}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`តម្លៃសរុប`}</div>
                                            </Col>
                                            <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                            </Col>
                                            <Col>
                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`$ ${dataNotification.total}`}</div>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>
                                        <Row>
                                            <Col className='align-items-start'>
                                                <div style={{ textAlign: 'start', fontFamily: 'Romnea', fontSize: 13, color: 'black' }}>{`${dataNotification.name}`}</div>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} className='align-items-center' style={{ padding: '0px' }}>
                                                <div style={{ textAlign: 'start', fontFamily: 'RalwayEng', fontSize: 13, color: 'black', fontWeight: 'bold' }}>{`${moment(dataNotification.date).fromNow(true)}`}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                    )
                }
                store.addNotification({
                    // title: `${dataNotification.desc}`,
                    // message: `${dataNotification.name}`,
                    content: MyNotification,
                    // type: "awesome",
                    container: 'bottom-right',
                    animationIn: ["animated", "zomeIn"],
                    animationOut: ["animated", "zoomOut"],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true
                    }
                    // width: 1000,
                })
                this.state.notifcationData.push(dataNotification)
                const CustomerSell = (!dataNotification.trans_ref) ? false : true;
                this.setState({ CustomerSell })
            });
            socket.emit('notification', []);
        })

        ///Permission
        let p = JSON.parse(localStorage.getItem('permission'));
        p.forEach((e) => {
                if(e.widget_en=='web_user_online'){
                    let webUserOnline = p.find( webUserOnline => (webUserOnline.widget_en == 'web_user_online'));
                    if(webUserOnline.grant.substring(0, 1) == 1){
                        this.setState({webUserOnlinePermission:  true});
                    }
                }
                if(e.widget_en=='web_operation'){
                    let webOperatiion = p.find( webOperatiion => (webOperatiion.widget_en == 'web_operation'));
                    if(webOperatiion.grant.substring(0, 1) == 1){
                        this.setState({operationPermission: true});
                    }
                }
                if(e.widget_en=='web_customer_location'){
                    let webCustomerLocation = p.find( webCustomerLocation => (webCustomerLocation.widget_en == 'web_customer_location'))
                    if(webCustomerLocation.widget_en=='web_customer_location' && webCustomerLocation.grant.substring(0, 1) == 1){
                        this.setState({webCustomerLocationPermission: true});
                    }
                }
            }
        );
        document.title = "BC Map";
    }

    componentWillUnmount() {
        // console.log("StateRefresh 5ss")
        socket.on('disconnect', (_) => {
            // console.log("SocketDisconnected", socket.disconnected)
        })
        clearInterval(this.intervalID);
    }

    _usersOnlineStatus() {
        // Check UserOnline or not
        var useronline = this.state.markers.map(d => (
            {
                "status": (this.state.onlineUsers.find(f => f.userid == d.USERID)) ? 1 : 0,
                "id": d.USERID,
                "image": d.image,
                "title": `${d.last_name} ${d.first_name}`
            }
        ))

        // Get count userOnline 
        let countUserOnline = useronline.filter( m => m.status == 1).map(x => x.status);
        this.setState({countUserOnline: countUserOnline});

        // // Get battery_client_event userOnline
        let userOnlineClientbatterySorted = {}

        const userOnlineSorted = useronline;
        
        let checkBatteryUserOnline = userOnlineSorted;

        if(checkBatteryUserOnline){
            socket.on("battery_client_event", data => {
                userOnlineClientbatterySorted = userOnlineSorted.map( item => (
                    {
                        status: item.status,
                        id: item.id,
                        image: item.image,
                        title: item.title,
                        charging: data.filter(i => i.userid === item.id).map(x => x.status),
                        level: data.filter(i => i.userid === item.id).map(x => x.level)
                    }
                ))
                userOnlineClientbatterySorted.sort(function (a, b) {
                    return a.status - b.status
                })

                this.setState({onlineUsers: countUserOnline});
                
                this.setState({ userOnlineSorted:  userOnlineClientbatterySorted.reverse() })
            })
        }
        
        userOnlineClientbatterySorted = userOnlineSorted.map( item => (
            {
                status: item.status,
                id: item.id,
                image: item.image,
                title: item.title,
                charging: 'Discharge',
                level: 0
            }
        ))
        userOnlineClientbatterySorted.sort(function (a, b) {
            return a.status - b.status
        })
        this.setState({ userOnlineSorted:  userOnlineClientbatterySorted.reverse()})
    }

    openPopUp(marker, id) {
        if (marker && marker.leafletElement) {
            marker.leafletElement.openPopup(id);
        }
    }

    handleChange = (selectedList) => {
        this.getDataLatLngPolyLine(selectedList);
    }

    getDataFromToken() {
        const axios = require('axios').default;
        let url = `${sever}/api/v1/userlocation?lastdate=true`;
        axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            const markers = res.data;
            this.setState({ markers });
            if (res.data.length != 0) {
                this.getDataLatLngPolyLine(res.data)
                this._listNotification()
                this._usersOnlineStatus()
            }
            // console.log("DataAPI", res.data)
        }).catch((err) => {
            window.localStorage.clear();
            window.location.reload();
        });
    }

    _listNotification() {
        const axios = require('axios').default;
        let url = `${sever}/api/v1/globalnotification`;
        axios.get(url, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            const notifcationData = res.data;
            // console.log("NotificaitonData", res.data)
            this.setState({ notifcationData });
        }).catch((err) => {
            console.log("AXIOS ERROR: ", err);
        });

    }

    getDataLatLngPolyLine(e) {
        if (e == null) {
            // console.log("Event Null", e)
            e = this.state.markersRefresh;
        } else if (e.length != 0) {
            // console.log("Event 1", e.length)
            const markersRefresh = e;
            const markersLoop = []
            this.setState({ markersRefresh, markersLoop, mapLoading: true })
        } else if (e.length == 0) {
            // console.log("Event None", e.length)
            const markersLoop = []
            e = this.state.markers;
            this.setState({ markersLoop })
        }
        var startday = moment().format(`YYYY-MM-DD[T]`);
        // var startday = moment().subtract(1, 'week').format('YYYY-MM-DD[T]');
        var endday = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
        // let url = `http://192.168.1.168:7000/api/v1/userlocation?fromdate=${moment().format(`YYYY-MM-DD[T]`)}00:00:00.000&todate=${moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}&userid=` + id;
        const axios = require('axios').default;
        let url = `${sever}/api/v1/userlocation?fromdate=${startday}00:00:00.000&todate=${endday}&userid=`;
        // e.map((e) => console.info('>>>>>>', e.ID))
        const p = Promise.all(e.map((e) => axios.get(`${url}${(e.USERID != null) ? e.USERID : e.value}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        })));
        p.then(result => {
            result.map((res) => {

                // let dataLoad = [];
                // dataLoad.push(res)
                if (res.data.length != 0) {
                    var dataNotnull = result.filter((f) => f.data != 0)
                    dataNotnull.map(async (momo) => {
                        // data.filter((e) => e.USERID == id)
                        // if (lengthData.length == 0) {
                        //     lengthData.push(dataPP)
                        // }
                        var responseUSERID = momo.data.map((e) => e.USERID)

                        // const dataSetID = [...new Set(userID)];

                        // this.state.userID.push(dataSetID);
                        // var markersLoopUSERID = this.state.markersLoop.map((e) => e.data.map((d) => d.USERID))
                        // var dataPo1 = markersLoopUSERID
                        var dataID = this.state.markersLoop.findIndex((e) => e.data.find((d) => responseUSERID[0] == d.USERID));
                        // var dataIDNew = this.state.markersLoop.findIndex((e) => e.data.find((d) => responseUSERID.find((f) => f == d.USERID)));
                        const userid = await responseUSERID[0];
                        if (dataID == -1) {
                            const markersLoop = dataNotnull;
                            this.state.userID.push(userid);
                            this.setState({ markersLoop })
                            this.getOptions();
                        } else if (this.state.markersLoop[dataID].data.length != dataNotnull[dataID].data.length) {
                            this.state.markersLoop[dataID].data.push(dataNotnull[dataID].data[dataNotnull[dataID].data.length - 1])
                            // console.log("OptionData2", this.state.markersLoop[dataID].data.length, dataNotnull[dataID].data.length, dataID)
                        } else if (this.state.markersLoop.length != dataNotnull.length) {
                            this.state.markersLoop.push(dataNotnull[dataNotnull.length - 1])
                            this.state.userID.push(userid);
                            this.getOptions()
                        }
                        // if (firstEvent == true) {
                        //     this.state.userID.push(userid);
                        //     this.setState({firstEvent: false})
                        //     this.getOptions();
                        //     console.log("Event 1 push")
                        // } else if (secondEvent == true) {
                        //     this.state.refreshID.push(userid);
                        //     this.setState({secondEvent: false})
                        //     this.getOptions();
                        //     console.log("Event 2 push")
                        // }
                        // else if ( setID.length == this.state.lengthData){
                        //     this.getOptions();
                        // }
                        // console.log("OptionData11", dataNotnull);
                        // console.log("OptionData11", this.state.markersLoop);
                        // console.log("OptionData1", [...new Set(this.state.userID)])
                        // console.log("DataFilter", this.state.markersLoop)
                        appStart = true;
                        // console.debug('appStart', appStart)
                    })
                } else {
                    this.setState({ loadingData: false });
                }
            })
        }).catch(e => {
            this.setState({ loadingData: false, noDataAlert: true })
        });
    }

    async getOptions() {
        if (this.state.markersLoop != null) {
            const dataID = [...new Set(this.state.userID)]
            var data = this.state.markers.map(d => (
                {
                    "value": d.USERID,
                    "type": d.type,
                    "label": `${d.last_name} ${d.first_name}`,
                    "latlng": [d.LATITUDE, d.LONGITUDE],
                    "typeid": d.typeid,
                    "username": d.username,
                    "CREATED_DATE": d.CREATED_DATE,
                    "SPEED": d.SPEED
                }
            ))
            var markersmap = await data.filter(e => dataID.find(f => f === e.value))
            this.setState({ selectOptions: markersmap, loadingData: false, mapLoading: false, lengthData: dataID.length, refreshData: true });
        }
    }
    showDetail = e => {
        this.setState({ showButton: true, type: e.type, display_name: '' });
        var latitude = "";
        var longitude = "";
        var name = "";
        // console.log("DetailData", e)
        latitude = e.LATITUDE;
        longitude = e.LONGITUDE;
        // var getLat = e.layer.getLatLng();
        name = `${e.USER.first_name} ${e.USER.last_name}`
        // localStorage.setItem('image', (e.image.length === 0) ? notFound : `http://192.168.1.168:7000/${e.image}`);
        localStorage.setItem('image', (e.image === null) ? notFound : `${sever}/${e.image}`);
        localStorage.setItem('name', name);
        const axios = require('axios').default;
        axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&accept-language=kh`, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        }).then((res) => {
            if (res.status === 200) {
                // console.log(res.data.display_name);
                const display_name = res.data.display_name;
                this.setState({ display_name, showButton: false });
            } else {
                console.log(res.statusText);
            }
        }).catch((err) => {
            console.log("AXIOS ERROR: ", err);
        });
    }

    handleEntailmentRequest = (e) => {
        this.setState({ loadingData: true })
        window.location.reload(false);
    }
    onCloseModal = () => {
        this.setState({ noDataAlert: false });
    };
    polylineLineMaker(e) {
        const polyLine = e.data.map((cord) => {
            return [cord.LATITUDE, cord.LONGITUDE];
        });
        return polyLine;
    }

    _removeNotification(e) {
        // var array = this.state.notifcationData.filter(function(s) {return s != e});
        var noti = this.state.notifcationData;
        noti.splice(e, 1)
        this.setState({ notifcationData: noti });
    }

    render() {
        const position = [this.state.lat, this.state.lng]
        const styles = {
            card: {
                borderRadius: 55,
            },
            cardImage: {
                height: 125,
                width: 125,
                backgroundColor: 'white'
            },
            styleCard: {
                marginBottom: 'auto',
                marginTop: 'auto',
                border: 0,
                justifyContent: 'center'
            },
            imageList: {
                height: 50,
                width: 50,
                borderRadius: '50%',
                position: 'relative',
                backgroundColor: 'white'
            }
        }
        this.selectStyle = {
            searchBox: {
                "border-radius": "15px",
                color: "#27AAE1",
                "border": "1px solid",
                "background-color": 'white'
            },
            inputField: { // To change input field position or margin
                textAlign: 'center',
                fontFamily: 'Content'
            },
            // multiselectContainer: {
            //   color: "red"
            // }
        };
        // console.info("Notification", this.state.notifcationData);
        var endMarker = this.state.markersLoop.map((e) => e.data[e.data.length - 1]);
        var firstMarker = this.state.markersLoop.map((e) => e.data[0]);
        if (this.state.loadingData == false) {
        return (
            <div class='heightAll'>
                <div class='row' style={{ width: "100%", height: "100vh", marginLeft: 0 }}>
                    {
                        this.state.openPanelLeft &&
                        <div style={{ backgroundColor: this.state.colorsByMap }}>
                            <Row style={{ justifyContent: 'center', }}>
                                <Card.Img src={logo} style={{ height: 150, width: 150, padding: 10 }} thumbnail />
                            </Row>
                            <div class='col' style={{ display: 'flex', justifyContent: 'center' }}>
                                <Row className="justify-content-md-center">
                                    <Card.Text style={{ textAlign: "center", fontFamily: 'Romnea', fontSize: 16, width: 'fit-content', padding: 5, color: this.state.textColorByMap }}>អ្នកប្រើប្រាស់សរុបចំនួន</Card.Text>
                                    <Card.Text style={{ textAlign: "center", width: 'fit-content', padding: 5, fontFamily: 'Romnea', fontSize: 17, color: this.state.textColorByMap }}>៖ {this.state.selectOptions.length} នាក់</Card.Text>
                                </Row>
                            </div>
                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>

                            <div class='col item-sidebar' style={{ display: 'flex', justifyContent: 'start' }} onClick={(e) => { this.handleEntailmentRequest(e) }}>
                                <Row style={{ margin: 10 }} className="align-content-md-center">
                                    {/* <Button className={'khmer-font'} onClick={(e) => { this.handleEntailmentRequest(e) }} variant="outline-primary" > */}
                                    <img src={refresh} style={{ height: 30, width: 30, marginRight: 10 }} />
                                    <div class='col' style={{ display: 'flex', alignItems: 'center' }}>
                                        <h5 style={{ color: this.state.textColorByMap, fontSize: '14px', textAlign: 'center', fontFamily: 'RalwayEng', margin: 0 }}>Refresh</h5>
                                    </div>
                                </Row>
                            </div>
                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>

                            <div class='col item-sidebar' style={{ display: 'flex', justifyContent: 'start' }}>
                                <Link style={{ textDecoration: 'none' }}
                                    to={{
                                        pathname: `/area/`,
                                    }} target="_blank">
                                    
                                    {
                                        (this.state.webCustomerLocationPermission == true) ? <Row style={{ margin: 10 }} className="align-content-md-center">
                                            <img src={customerLogo} style={{ height: 30, width: 30, marginRight: 10 }} />
                                            <div class='col' style={{ display: 'flex', alignItems: 'center' }}>
                                                <h5 style={{ color: '#414042', fontSize: '14px', textAlign: 'center', fontFamily: 'Content', color: this.state.textColorByMap, margin: 0, }}>ទីតាំងអតិថិជន</h5>
                                            </div>
                                        </Row> : null
                                    }

                                </Link>
                            </div>
                            <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                            {
                                (this.state.webCustomerLocationPermission == true) ? <Row style={{ justifyContent: 'center' }}>
                                    <Col style={{ margin: "15px", maxWidth: 'fit-content' }}>
                                        <Multiselect disablePreSelectedValues={true} displayValue='label' style={this.selectStyle} value={this.state.markersRefresh} className={'khmer-font-select'} onRemove={this.handleChange} onSelect={this.handleChange} options={this.state.selectOptions} placeholder={"អ្នកប្រើប្រាស់ទាំងអស់"} />
                                    </Col>
                                </Row> : null
                            }
                            {
                                (this.state.webUserOnlinePermission == true) ? <Col style={{ marginTop: 10, maxHeight: 'fit-content' }} className="align-content-md-start">
                                <Card.Text style={{ fontFamily: 'Romnea', color: this.state.textColorByMap }}>
                                    User Online ( {this.state.countUserOnline.length} នាក់)
                                </Card.Text>
                                <Row className='justify-content-md-start'><hr style={{ width: '380px', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>
                                <div style={{ height: 'auto', height: '50vh', overflow: 'scroll' }} >
                                <ul className="list-group" style={{ height: 'fit-content', padding: '0% 0% 10% 0%', margin: '0% 0% 10% 0%', }}>
                                        {
                                        this.state.userOnlineSorted.map(listitem => (
                                                <Link
                                                style={{ textDecoration: 'none' }}
                                                to={{
                                                    pathname: `/detail/${listitem.id}`,
                                                }} target="_blank">
                                                <li className="list-group-item" style={{ backgroundColor: this.state.colorsByMap, border: 0 }}>
                                                    {(listitem.status == 1) ?
                                                        <Row className='justify-content-md-start item-sidebar'>
                                                            <Col xs={3} sm={3} md={3} lg={3}>
                                                                <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={styles.imageList} src={`${sever}/${listitem.image}`} />
                                                                <div class='statusOverlay' style={{ position: "absolute", left: "52px" ,backgroundColor: "#01DF01", borderColor: "#01DF01" }}></div>
                                                            </Col>
                                                            <Col xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'RalwayEng', color: this.state.textColorByMap }}>{`${listitem.title}`}</div>
                                                            </Col>
                                                            <Col xs={4} sm={4} md={4} lg={4}>
                                                                <Battery percent={(listitem.level != '' ? listitem.level : '')} size={(listitem.level != '' ? 40 : 0)} showPercentLabel={(listitem.level != '' ? listitem.level : '')} color={listitem.level>50?'green':listitem.level>20?'orange':'red'} labelPosition="right"  charging={listitem.charging=="Charging"?true:false} outlined={false} labelColor="white" labelSize={15} />
                                                            </Col>
                                                        </Row>
                                                        : <Row className='justify-content-md-start item-sidebar'>
                                                            <Col xs={3} sm={3} md={3} lg={3}>
                                                                <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={styles.imageList} src={`${sever}/${listitem.image}`} />
                                                                <div class='statusOverlay' style={{ position: "absolute", left: "52px", backgroundColor: 'red', borderColor: 'red' }}></div>
                                                            </Col>
                                                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ textAlign: 'start', padding: 0, fontFamily: 'RalwayEng', color: this.state.textColorByMap }}>{`${listitem.title}`}</div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </li>
                                            </Link>
                                        ))
                                        }
                                    </ul> 
                                </div>
                            </Col> : null
                            }
                        </div>
                    }
                    <Col className="map" style={{ padding: 0, margin: 0}}>
                        {
                            (this.state.mapLoading) ? <LoadingScreen
                            loading={true}
                            bgColor='#f1f1f1'
                            spinnerColor='#9ee5f8'
                            textColor='#676767'
                            text='Loading data...'
                        >
                        </LoadingScreen>
                            :
                            <Map center={position} zoomControl={false} zoom={this.state.zoom} onClick={() => this.setState({ openPanelRightMap: false, openPanelRightNoti: false })} ref={(mapEl) => this.mapEl = mapEl} style={{
                                bottom: 0, top: 0, padding: 0,
                                margin: 0, height: '100%'
                            }}>
                                <Button className='overlayButtonLeft'
                                    onClick={() => this.setState({ openPanelLeft: !this.state.openPanelLeft })}
                                >
                                    <IconContext.Provider value={{ style: { fontSize: '20px', paddingBottom: 2, color: '#0275d8' } }}>
                                        {
                                            (!this.state.openPanelLeft) ? <FaArrowAltCircleRight /> : <FaArrowAltCircleLeft />
                                        }
                                    </IconContext.Provider>
                                </Button>

                                <Col>
                                {/* Bell  */}
                                    
                                    {
                                        (this.state.operationPermission == true)  ? <Button className='overlayButtonRight' style={{ backgroundColor: (!this.state.openPanelRightNoti) ? '#808285' : "#F0F0F0", color: (!this.state.openPanelRightNoti) ? '#808285' : "#F0F0F0", borderColor: (!this.state.openPanelRightNoti) ? '#808285' : "#F0F0F0" }}
                                        onClick={() => this.setState({ openPanelRightNoti: !this.state.openPanelRightNoti, openPanelRightMap: false })}
                                         >
                                             <img src={bell} style={{ width: 20, height: 20 }} />
                                         </Button> : ''
                                    }

                                    <Button className='overlayButtonRightLayout' style={{ backgroundColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", color: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0", borderColor: (!this.state.openPanelRightMap) ? '#808285' : "#F0F0F0" }}
                                        onClick={() => this.setState({ openPanelRightMap: !this.state.openPanelRightMap, openPanelRightNoti: false })}
                                    >
                                        <img src={map} style={{ width: 20, height: 20 }} />
                                    </Button>
                                </Col>
                                <TileLayer
                                    attribution={this.state.attribution}
                                    url={this.state.urlMap}
                                    minZoom={1}
                                    maxZoom={19}
                                />
                                {
                                    this.state.markersLoop.map((e, i) =>
                                        <div>
                                            {
                                                e.data.map((d, index) => (
                                                    <Marker
                                                        icon={
                                                            (firstMarker.find((e) => e.ID == d.ID)) ? Leaflet.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: `<div style='background-color:${colors[i]};' class='marker-pinN'><div class='overlayMarkerHomeStart' style='border-color: ${colors[i]}; border-style: solid;: 1 solid;' ><p class='pStyle' style='position: absolute;'>ចាប់ផ្តើម</p></div>` + `<img class='awesomeFE' src='${`${sever}/api/v1/imageresize?url=${d.USER.image}&quality=5`}' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                                iconSize: [15, 21],
                                                                iconAnchor: [8, 21]
                                                            }) : (endMarker.find((e) => e.ID == d.ID)) ? Leaflet.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: `<div style='background-color:${colors[i]};' class='marker-pinN'><div class='overlayMarkerHome' style='border-color: ${colors[i]}; border-style: solid;: 1 solid;' ><p class='pStyle' style='position: absolute;'>បញ្ចប់</p></div>` + `<img class='awesomeFE' src='${`${sever}/api/v1/imageresize?url=${d.USER.image}&quality=5`}' onerror="this.onerror=null;this.src='${notFound}';"}/>`,
                                                                iconSize: [15, 21],
                                                                iconAnchor: [8, 21]
                                                            }) : Leaflet.divIcon({
                                                                className: 'custom-div-icon',
                                                                html: `<div style='background-color:${colors[i]};' class='marker-Simple'></div>`,
                                                                iconSize: [15, 21],
                                                                iconAnchor: [8, 21]
                                                            })
                                                            // Leaflet.divIcon({
                                                            //     className: "custom-div-icon",
                                                            //     iconSize: [15, 21],
                                                            //     iconAnchor: [8, 21],
                                                            //     html: `<div style='background-color:${colors[i]};' class='marker-pinN></div>`
                                                            // })
                                                        }
                                                        position={[d.LATITUDE, d.LONGITUDE]}
                                                        // ref={console.log("DataMarkers2", [d.LATITUDE, d.LONGITUDE])}
                                                        // ref={ref => this.markerRefs[d.userid] = ref}
                                                        onclick={() => this.showDetail(d)}
                                                        // onMouseOver={e => {
                                                        //     e.target.openPopup();
                                                        //   }}
                                                        //   onMouseOut={e => {
                                                        //     e.target.closePopup();
                                                        //   }}
                                                        key={`marker-${d.ID}`}
                                                    >
                                                        <Popup className='custom-popup' closeButton={false}>
                                                            <div style={{ backgroundColor: this.state.colorsByMap, borderRadius: 25, borderWidth: 1, borderStyle: 'solid', borderColor: this.state.borderColorByMap, opacity: this.state.opacity }}>
                                                                <div style={styles.styleCard}>
                                                                    <Row style={{ justifyContent: 'center', }}>
                                                                        <div class="avatar-flip" style={{ backgroundColor: 'rgba(240,240,240,0.1)' }}>
                                                                            <img onClick={() => this.setState({ profile: d.USER.image, profileOpen: true })} style={styles.cardImage} variant="top" class='cover' onError={(e) => { e.target.onerror = null; e.target.src = notFound }} src={`${sever}/api/v1/imageresize?url=${d.USER.image}&quality=30`} />
                                                                            {/* <Card.Img variant="top" class='cover' src={(PICTURE.length == 0) ? notFound : `http://192.168.1.168:7000/${d.USER.image}`} style={styles.cardImage} /> */}
                                                                        </div>
                                                                    </Row>
                                                                    <Col style={{ justifyContent: 'center', textAlign: "center", marginTop: '70px' }}>
                                                                        <Card.Title style={{ fontSize: '20px', fontFamily: 'RalwayEng', color: this.state.textColorByMap }}>{d.USER.last_name} {d.USER.first_name}</Card.Title>
                                                                        <Container style={{ backgroundColor: "#27AAE1", width: 'fit-content', borderRadius: 9, padding: '0px 6px 0px 5px' }}>
                                                                            <Card.Title style={{ color: 'white', padding: '4px 1px 4px 1px', fontSize: '15px', fontFamily: 'RalwayEng', fontWeight: "bold !important" }}>
                                                                                {d.USER.type}
                                                                            </Card.Title>
                                                                        </Container>
                                                                    </Col>
                                                                </div>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>

                                                                <Row style={{ marginTop: 5 }} className='justify-content-md-center'>
                                                                    {
                                                                        (d.USER.type == "SELLER") && <div class='col' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/customer/${d.USER.typeid}`,
                                                                                }} target="_blank" >
                                                                                <Col className='justify-content-md-center' style={{ padding: 0, display: 'grid' }}>
                                                                                    <img src={customerLogo} style={{ height: 35, width: 35, display: 'flex', justifySelf: 'center' }} />
                                                                                    <h5 style={{ color: this.state.textColorByMap, fontSize: '14px', fontFamily: 'RalwayEng', textAlign: 'center', paddingTop: "3px" }}>Customer</h5>
                                                                                </Col>
                                                                            </Link>
                                                                        </div>
                                                                    }
                                                                    <div class='col' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/detail/${d.USERID}`,
                                                                            }} target="_blank">
                                                                            <Col className='justify-content-md-center' style={{ padding: 0, display: 'grid' }}>
                                                                                <img src={detail} style={{ height: 35, width: 35, display: 'flex', justifySelf: 'center' }} />
                                                                                <h5 style={{ color: this.state.textColorByMap, fontSize: '14px', textAlign: 'center', fontFamily: 'RalwayEng', paddingTop: "3px" }}>Detail</h5>
                                                                            </Col>
                                                                        </Link>
                                                                    </div>
                                                                </Row>
                                                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: 0 }}></hr></Row>

                                                                <div style={{ padding: 10, display: "inline-block", justifyContent: 'center' }}>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={address} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        {
                                                                            (this.state.display_name.length != 0) ? <Col style={{ paddingLeft: 0, width: 'fit-content' }}><Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                                {this.state.display_name}
                                                                            </Card.Text></Col> : <Col style={{ justifyContent: 'center', justifyItems: 'center' }}><Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            /></Col>
                                                                        }
                                                                    </Row>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={speed} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        <Col style={{ width: 'fit-content', display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
                                                                            <Card.Text className='khmer-font' style={{ paddingTop: 10, color: this.state.textColorByMap }}>
                                                                                ល្បឿន៖ {d.SPEED > 0 ? (d.SPEED * 1.60934).toString().slice(0, 5) : 0} Km/h
                                                                                </Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row style={{ padding: '10px 0px 0px 0px', }}>
                                                                        <Col md={3} style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={calender} style={{ height: 30, width: 30 }} />
                                                                        </Col>
                                                                        <Col style={{ paddingLeft: 0, width: 'fit-content' }}>
                                                                            <Card.Text className='khmer-font' style={{ color: this.state.textColorByMap }}>
                                                                                {moment.parseZone(d.CREATED_DATE).locale('km').format(`ថ្ងៃ dddd, Do ខែ MMMM ឆ្នាំ YYYY, hh:mm នាទី a`)}
                                                                            </Card.Text>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            {/* <div style={{ backgroundColor: '#0275d8', height: 25, borderBottomRightRadius: 15, borderBottomLeftRadius: 15 }}></div> */}
                                                        </Popup>
                                                    </Marker>
                                                )
                                                )
                                            }
                                            <Polyline positions={this.polylineLineMaker(e)} color={colors[i]}></Polyline>
                                        </div >
                                    )
                                }
                            </Map>
                        }
                    </Col >
                    {/* Card panel Right Open Notification and Map */}
                    {
                        (this.state.openPanelRightNoti) ?
                            <div style={{ backgroundColor: this.state.colorsByMap, width: 'auto' }}>
                                <Col style={{ display: 'flex', justifyContent: 'start', backgroundColor: this.state.colorsByMap }}>
                                    <Row style={{ margin: '15px 0px 0px 10px', justifyContent: 'flex-start', width: 'fit-content' }}>
                                        <Card.Title style={{ fontFamily: 'Romnea', fontSize: 25, padding: 10, margin: 0, padding: '10px 0px 0px 0px', color: this.state.textColorByMap }}>
                                            ប្រតិបត្តិការ ({this.state.notifcationData.length})
                                    </Card.Title>
                                    </Row>
                                </Col>
                                <Row className='justify-content-md-center' style={{ margin: '0px 0px 0px 0px', padding: 3, margin: 5 }}>
                                    <Col>
                                        <Row style={{ margin: 0 }}>
                                            <Card.Title style={{ color: this.state.textColorByMap, padding: '0px 0px 0px 0px', margin: "5px 0px 5px 0px", fontSize: '15px', fontFamily: 'Content' }}>
                                                សរុបលក់៖
                                            </Card.Title>
                                            <Card.Title style={{ color: this.state.textColorByMap, padding: '0px 0px 0px 5px', margin: "5px 0px 5px 0px", fontSize: '15px', fontFamily: 'Romnea' }}>
                                                {this.state.notifcationData.filter(e => e.title == "Sale").length}
                                            </Card.Title>
                                        </Row>
                                    </Col>
                                    <Col style={{ padding: 0 }} >
                                        <Container style={{ backgroundColor: "grey", width: 'fit-content', borderRadius: 9, padding: '1px 6px' }} onClick={() => this.setState({ notifcationData: [] })}>
                                            <Card.Title style={{ color: 'white', padding: '0px 0px 0px 0px', margin: "5px 0px 5px 0px", fontSize: '13px', fontFamily: 'Content' }}>
                                                លុបទាំងអស់
                                         </Card.Title>
                                        </Container>
                                    </Col>
                                </Row>
                                <ul className="list-group" style={{ height: '90vh', maxHeight: 'fit-content', overflowY: 'scroll' }}>
                                    {_.sortBy(this.state.notifcationData, [function (notificationDateItem) { return notificationDateItem.date; }]).reverse().map((listitem, i) => (
                                        <Card style={{ borderRadius: 10, margin: 2, padding: 2 }}>
                                            {
                                                (!listitem.trans_ref) ?
                                                    <Row className='justify-content-md-center' style={{ margin: 0, width: '-webkit-fill-available', padding: '0px 5px 0px 5px' }}>
                                                        <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'center' }}>
                                                            <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={{
                                                                height: 60,
                                                                width: 60,
                                                                borderRadius: '50%',
                                                                position: 'cover',
                                                                boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px'
                                                            }} src={`${sever}/api/v1/imageresize?url=${listitem.profile}&quality=50`} />
                                                        </Col>
                                                        <Col style={{ padding: '0px 10px 0px 10px', width: '-webkit-fill-available' }}>
                                                            <Row>
                                                                <Col className='align-items-start' style={{ padding: '5px 0px 10px 15px', margin: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: 'black', fontSize: 15, maxLines: 2 }}>{`${listitem.desc}​`}</div>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3} lg={3} className='align-items-end'>
                                                                    <Row className='justify-content-md-end' style={{ padding: '5px 10px 0px 0px' }}>
                                                                        <div onClick={() => this._removeNotification(i)}>
                                                                            <img src={cancel} style={{ width: 20, height: 20, opacity: '70%' }} />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={4} sm={4} md={4} lg={4} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`លេខកូដ`}</div>
                                                                </Col>
                                                                <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                                                </Col>
                                                                <Col className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${listitem.code}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>
                                                            <Row>
                                                                <Col className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', fontFamily: 'Romnea', fontSize: 13, color: 'black' }}>{`${listitem.name}`}</div>
                                                                </Col>
                                                                <Col xs={5} sm={5} md={5} lg={5} className='align-items-center' style={{ padding: '0px' }}>
                                                                    <div style={{ textAlign: 'start', fontFamily: 'Content', fontSize: 12, fontWeight: 'bold', color: '#27AAE1' }}>{`${moment(listitem.date).fromNow()}`}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row className='justify-content-md-center' style={{ margin: 0, width: '-webkit-fill-available', padding: '0px 5px 0px 5px' }}>
                                                        <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'center', padding: '0px', justifyContent: 'center' }}>
                                                            <img onError={(e) => { e.target.onerror = null; e.target.src = profile }} style={{
                                                                height: 60,
                                                                width: 60,
                                                                borderRadius: '50%',
                                                                position: 'cover',
                                                                boxShadow: 'rgb(0, 0, 0) -1px 1px 17px -5px'

                                                            }} src={`${sever}/api/v1/imageresize?url=${listitem.profile}&quality=50`} />
                                                        </Col>
                                                        <Col style={{ padding: '0px 10px 0px 10px', width: '-webkit-fill-available' }}>
                                                            <Row>
                                                                <Col className='align-items-start' style={{ padding: '5px 0px 10px 15px', margin: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Romnea', color: 'black', fontSize: 15, maxLines: 2 }}>{`${listitem.desc}​`}</div>
                                                                </Col>
                                                                <Col xs={3} sm={3} md={3} lg={3} className='align-items-end'>
                                                                    <Row className='justify-content-md-end' style={{ padding: '5px 10px 0px 0px' }}>
                                                                        <div onClick={() => this._removeNotification(i)}>
                                                                            <img src={cancel} style={{ width: 20, height: 20, opacity: '70%' }} />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} sm={6} md={6} lg={6} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`លេខវិក័យបត្រ`}</div>
                                                                </Col>
                                                                <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                                                </Col>
                                                                <Col className='align-items-start' style={{ padding: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${listitem.trans_ref}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} sm={6} md={6} lg={6} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`ផ្សារ`}</div>
                                                                </Col>
                                                                <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                                                </Col>
                                                                <Col className='align-items-start' style={{ padding: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${listitem.market}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} sm={6} md={6} lg={6} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`តូបលេខ`}</div>
                                                                </Col>
                                                                <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                                                </Col>
                                                                <Col className='align-items-start' style={{ padding: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`${listitem.store}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={6} sm={6} md={6} lg={6} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`តម្លៃសរុប`}</div>
                                                                </Col>
                                                                <Col xs={1} sm={1} md={1} lg={1} className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 12, maxLines: 1 }}>{`៖`}</div>
                                                                </Col>
                                                                <Col className='align-items-start' style={{ padding: 0 }}>
                                                                    <div style={{ textAlign: 'start', padding: 0, fontFamily: 'Content', color: 'black', fontSize: 13, maxLines: 1 }}>{`$ ${listitem.total}`}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='justify-content-md-center'><hr style={{ width: '90%', height: 5, color: '#A7A9AC', margin: 0, marginTop: 3 }}></hr></Row>
                                                            <Row>
                                                                <Col className='align-items-start'>
                                                                    <div style={{ textAlign: 'start', fontFamily: 'Romnea', fontSize: 13, color: 'black' }}>{`${listitem.name}`}</div>
                                                                </Col>
                                                                <Col xs={5} sm={5} md={5} lg={5} className='align-items-center' style={{ padding: '0px' }}>
                                                                    <div style={{ textAlign: 'start', fontFamily: 'Content', fontSize: 12, fontWeight: 'bold', color: '#27AAE1' }}>{`${moment(listitem.date).fromNow()}`}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                            }
                                        </Card>
                                    ))}
                                </ul>
                            </div>
                            : (this.state.openPanelRightMap) ? <Col xs={3} sm={3} md={2} className='option-list' style={{ backgroundColor: this.state.colorsByMap, width: 'fit-content', height: "100%", padding: 10 }}>
                                <Row style={{ margin: 10, justifyContent: 'start' }}>
                                    <Card.Title style={{ fontFamily: 'Romnea', fontSize: 18, padding: 10, color: this.state.textColorByMap }}>
                                        ជ្រើសរើសផែនទី
                                </Card.Title>
                                </Row>
                                <div onClick={() =>
                                    this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទីដើម</Row>
                                        <Row>
                                            <img src={openStreetMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: "0px 0px 5px 0px" }}></hr></Row>

                                <div onClick={() =>
                                    this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '`&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`', urlMap: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png' })
                                }>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Humannitarain</Row>
                                        <Row>
                                            <img src={HumannitarainMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>

                                <div onClick={() => this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community', urlMap: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Sattelite</Row>
                                        <Row>
                                            <img src={sattlelite} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>

                                <div onClick={() => this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', urlMap: 'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី HikeBikeMap</Row>
                                        <Row>
                                            <img src={HikeBikeMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>
                                <div onClick={() => this.setState({ RefreshbuttonColor: 'white', lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://api.mapbox.com/styles/v1/bcinnovationteam/ckh0fk5up031i19mtuhri7pfz/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYmNpbm5vdmF0aW9udGVhbSIsImEiOiJjazVveXBwdzUxZXp0M29wY3djN2xjMmNhIn0.KSQ7lGfLfQW3G91MZoZX0A" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Modern</Row>
                                        <Row>
                                            <img src={modern} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>

                                <div onClick={() => this.setState({ RefreshbuttonColor: 'white', lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទីខ្មៅ</Row>
                                        <Row>
                                            <img src={darkMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>
                                <div onClick={() => this.setState({ RefreshbuttonColor: 'white', lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: "https://maps.targomo.com/styles/fiord-color-gl-style/rendered/{z}/{x}/{y}.png" })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី FiordColor</Row>
                                        <Row>
                                            <img src={fiord} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>
                                <div onClick={() => this.setState({ RefreshbuttonColor: 'white', lineColorByMap: '#1aade6d9', colorMarker: '#1aade6d9', textColorByMap: 'white', colorsByMap: '#242424', borderColorByMap: "#1aade6d9", attribution: '<a href=\"http://www.openmaptiles.org/\" target=\"_blank\">&copy; OpenMapTiles</a> <a href=\"http://www.openstreetmap.org/about/\" target=\"_blank\">&copy; OpenStreetMap contributors</a>', urlMap: 'https://maps.targomo.com/styles/gray-gl-style/rendered/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី Grey</Row>
                                        <Row>
                                            <img src={grayMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>
                                <div onClick={() => this.setState({ RefreshbuttonColor: 'black', lineColorByMap: 'grey', colorMarker: '#A7A9AC', textColorByMap: 'black', colorsByMap: 'white', borderColorByMap: "#A7A9AC", attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors', urlMap: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png' })}>
                                    <Col style={{ margin: "0px 10px 10px 10px", padding: "0px 10px 10px 10px" }}>
                                        <Row style={{
                                            fontFamily: 'Content', fontSize: 13, color: this.state.textColorByMap
                                        }}>ផែនទី ស</Row>
                                        <Row>
                                            <img src={whiteMap} style={{ width: '90%', objectFit: 'cover', height: 50, borderRadius: 15 }} />
                                        </Row>
                                    </Col>
                                </div>
                                <Row className='justify-content-md-center'><hr style={{ width: '80%', height: 0.1, opacity: 0.5, backgroundColor: this.state.lineColorByMap, margin: '0px 0px 5px 0px' }}></hr></Row>

                            </Col> : null
                    }
                </div>

                <div class='overlayNotification'>
                    <ReactNotification />
                </div>

                <Modal className="modal-container"
                    show={this.state.noDataAlert}
                    onHide={this.onCloseModal}
                    animation={true}
                    size="sm"
                    centered
                    bsSize="small">

                    <Modal.Header>
                        <Modal.Title>No Data...</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button onClick={this.onCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {
                    this.state.loadingData && <LoadingScreen
                        loading={true}
                        bgColor='#f1f1f1'
                        spinnerColor='#9ee5f8'
                        textColor='#676767'
                        text='Loading data...'
                    >
                    </LoadingScreen>
                }
                {this.state.profileOpen &&
                    <div class="overlay">
                        <Lightbox
                            mainSrc={`${sever}/${this.state.profile}`}
                            onError={(e) => { e.target.onerror = null; e.target.src = notFound }}
                            onCloseRequest={() => this.setState({ profileOpen: false })}
                        />
                    </div>
                }
            </div>
        )
    } else {
        return <LoadingScreen
            loading={true}
            bgColor='#f1f1f1'
            spinnerColor='#9ee5f8'
            textColor='#676767'
            text='Loading data...'
        >
        </LoadingScreen>
    }
    }
};

const colors = [
    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca",
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
    "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
    "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
    "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
    "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
    "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
    "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
    "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
    "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
    "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
    "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
]

export default withRouter(MapHome);

